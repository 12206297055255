import { getFirestore, getDoc, getDocs, doc, collection, query, limit, orderBy, startAfter, where, updateDoc, writeBatch, increment, onSnapshot } from 'firebase/firestore'

export default {
  state: {
    orders: null,
    allOrdersFetched: false,
    ordersReset: 0,
    lastFetchedOrder: null,
    ordersFilters: {
      start: null,
      end: null,
      status: null
    },
    orderStatuses: {},
    ordersUnsubscribe: null,
    showEditOrder: null,
    showOrderSettings: false,
    orderSearchStrOrderId: null,
    ordersSearchStr: ''
  },
  mutations: {
    setOrdersSearchStr(state, value) {
      state.ordersSearchStr = value
    },
    setOrderSearchStrOrderId(state, value) {
      state.orderSearchStrOrderId = value
    },
    setShowOrderSettings(state, value) {
      state.showOrderSettings = value
    },
    setShowEditOrder(state, value) {
      state.showEditOrder = value
    },
    setOrder(state, orderData) {
      if (!orderData || !orderData.orderId) { return }

      if (!state.orders) { state.orders = {} }

      state.orders[orderData.orderId] = orderData
    },
    updateOrder(state, { orderId, data }) {
      if (orderId && state.orders && state.orders[orderId]) {
        const keys = Object.keys(data)
        for (const key of keys) {
          state.orders[orderId][key] = data[key]
        }
      }
    },
    setOrderStatuses(state, { orderId, statuses }) {
      if (orderId && statuses) {
        state.orderStatuses[orderId] = statuses
      }
    },
    addOrderStatuses(state, { orderId, status }) {
      if (orderId && status && state.orderStatuses[orderId]) {
        state.orderStatuses[orderId].push(status)
      }
    },
    setAllOrdersFetched(state, value) {
      state.allOrdersFetched = value
    },
    setLastFetchedOrder(state, order) {
      state.lastFetchedOrder = order
    },
    resetOrders(state) {
      state.ordersReset = +new Date()
    },
    setOrdersFilters(state, filters) {
      if (filters) {
        state.ordersFilters = filters
        state.ordersReset = +new Date()
      }
    },
    setOrdersUnsubscribe(state, value) {
      state.ordersUnsubscribe = value
    },
    clearOrders(state) {
      state.orders = {}
      state.ordersReset = +new Date()
    },
    clearOrdersFilters(state) {
      state.ordersFilters = {
        start: null,
        end: null,
        status: null
      }
      state.ordersReset = +new Date()
    },
    clearInfo(state) {
      state.orders = null
      state.allOrdersFetched = false
      state.lastFetchedOrder = null
      state.ordersFilters = {
        start: null,
        end: null,
        status: null
      }
      state.orderStatuses = {}
      state.ordersUnsubscribe = null
      state.showEditOrder = null
      state.showOrderSettings = false
      state.orderSearchStrOrderId = null
    }
  },
  actions: {
    async fetchOrder({ commit, dispatch }, orderId) {
      if (!orderId) {
        commit('setError', 'Ошибка')
        return
      }

      try {
        await getDoc(doc(getFirestore(), `orders/${orderId}`))
          .then(async order => {
            if (order.exists()) {
              const orderData = await dispatch('orderFetched', { orderData: order.data(), orderId: order.id })
              await commit('setOrder', orderData)
              commit('resetOrders')
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchOrders({ commit, dispatch, getters }) {
      if (getters.allOrdersFetched) { return }

      const maxLength = 10

      try {
        const ref = collection(getFirestore(), 'orders')

        const queries = [orderBy('timestamp', 'desc'), limit(maxLength)]

        const lastFetchedOrder = await getters.lastFetchedOrder
        if (lastFetchedOrder) {
          queries.push(startAfter(lastFetchedOrder))
        }

        if (getters.ordersSettingsFiltered) {
          if (getters.ordersFilters.start) { queries.push(where('timestamp', '>=', getters.ordersFilters.start)) }
          if (getters.ordersFilters.end) { queries.push(where('timestamp', '<', getters.ordersFilters.end)) }
          if (getters.ordersFilters.status) { queries.push(where('status', '==', getters.ordersFilters.status)) }
        }

        const q = query(ref, ...queries)

        const orders = await getDocs(q)
        if (!orders.empty) {
          for (const order of orders.docs) {
            if (order.exists()) {
              const orderData = await dispatch('orderFetched', { orderData: order.data(), orderId: order.id })
              await commit('setOrder', orderData)

              if (!getters.orderStatuses[order.id]) {
                await dispatch('fetchOrderStatuses', order.id)
              }
            }
          }

          commit('resetOrders')

          await commit('setLastFetchedOrder', orders.docs[orders.docs.length - 1])
        }

        if (orders.empty || orders.size < maxLength) {
          await commit('setAllOrdersFetched', true)
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchOrdersForGroup({ commit, dispatch, getters }, groupId) {
      if (!groupId) { return }

      try {
        const ref = collection(getFirestore(), 'orders')
        const queries = [where('groupId', '==', groupId), orderBy('timestamp', 'desc')]
        const q = query(ref, ...queries)

        const orders = []

        const answer = await getDocs(q)
        if (!answer.empty) {
          for (const order of answer.docs) {
            if (order.exists()) {
              const orderData = await dispatch('orderFetched', { orderData: order.data(), orderId: order.id })
              await commit('setOrder', orderData)
              orders.push(orderData)

              if (!getters.orderStatuses[order.id]) {
                await dispatch('fetchOrderStatuses', order.id)
              }
            }
          }
        }

        return orders
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchOrdersByStudentId({ commit, dispatch, getters }, studentId) {
      if (!studentId) { return }

      try {
        const ref = collection(getFirestore(), 'orders')
        const queries = [where('studentId', '==', studentId), orderBy('timestamp', 'desc')]
        const q = query(ref, ...queries)

        const orders = []

        const answer = await getDocs(q)
        if (!answer.empty) {
          for (const order of answer.docs) {
            if (order.exists()) {
              const orderData = await dispatch('orderFetched', { orderData: order.data(), orderId: order.id })
              await commit('setOrder', orderData)
              orders.push(orderData)

              if (!getters.orderStatuses[order.id]) {
                await dispatch('fetchOrderStatuses', order.id)
              }
            }
          }
        }

        return orders
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchOrdersByOrderStudentId({ commit, dispatch, getters }, studentId) {
      if (!studentId) {
        commit('setError', 'Не указан Stident ID')
        return false
      }

      try {
        const ref = collection(getFirestore(), 'orders')
        const queries = [where('orderStudentId', '==', studentId), orderBy('timestamp', 'desc')]
        const q = query(ref, ...queries)

        const orders = []

        const answer = await getDocs(q)
        if (!answer.empty) {
          for (const order of answer.docs) {
            if (order.exists()) {
              const orderData = await dispatch('orderFetched', { orderData: order.data(), orderId: order.id })
              await commit('setOrder', orderData)
              orders.push(orderData)

              if (!getters.orderStatuses[order.id]) {
                await dispatch('fetchOrderStatuses', order.id)
              }
            }
          }
        }

        return orders
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchOrderStatuses({ commit }, orderId) {
      if (!orderId) {
        commit('setError', 'Не указан Order ID')
        return false
      }

      try {
        const ref = collection(getFirestore(), `orders/${orderId}/statuses`)
        const q = query(ref)

        const statuses = []

        const answer = await getDocs(q)
        if (!answer.empty) {
          for (const status of answer.docs) {
            if (status.exists()) {
              const statusData = status.data()
              if (statusData.timestamp && statusData.timestamp.toDate) {
                statusData.timestamp = statusData.timestamp.toDate()
              }
              statuses.push(statusData)
            }
          }
        }

        await commit('setOrderStatuses', { orderId, statuses })
        commit('resetOrders')
      } catch (e) {
        commit('setError', e)
      }
    },
    async subscribeToOrders({ dispatch, commit, getters }) {
      try {
        await dispatch('unsubscribeOrders')

        const ref = collection(getFirestore(), 'orders')
        const q = query(ref, where('updated', '>=', new Date()), orderBy('updated', 'asc'))

        const unsubscribe = onSnapshot(q, async querySnapshot => {
          querySnapshot.docChanges().forEach(async change => {
            const orderData = await dispatch('orderFetched', { orderData: change.doc.data(), orderId: change.doc.id, forceGroupFetch: true })
            await commit('setOrder', orderData)

            if (!getters.orderStatuses[change.doc.id]) {
              await dispatch('fetchOrderStatuses', change.doc.id)
            }
          })

          commit('resetOrders')
        }, (error) => {
          commit('setError', error)
        })

        await commit('setOrdersUnsubscribe', unsubscribe)
      } catch (e) {
        commit('setError', e)
      }
    },
    async unsubscribeOrders({ commit, getters }) {
      try {
        const ordersUnsubscribe = getters.ordersUnsubscribe
        if (ordersUnsubscribe) {
          await ordersUnsubscribe()
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async orderFetched({ dispatch, getters }, { orderData, orderId, forceGroupFetch }) {
      if (!orderData || !orderId) { return }

      if (orderData.timestamp && orderData.timestamp.toDate) {
        orderData.timestamp = orderData.timestamp.toDate()
      }

      if (orderData.updated && orderData.updated.toDate) {
        orderData.updated = orderData.updated.toDate()
      }

      if (orderData.sertificateSent && orderData.sertificateSent.toDate) {
        orderData.sertificateSent = orderData.sertificateSent.toDate()
      }

      if (orderData.orderStudentId && !getters.students[orderData.orderStudentId]) {
        await dispatch('fetchStudentById', orderData.orderStudentId)
      }

      if (orderData.studentId && !getters.students[orderData.studentId]) {
        await dispatch('fetchStudentById', orderData.studentId)
      }

      if (orderData.groupId && (!getters.groups[orderData.groupId] || forceGroupFetch)) {
        await dispatch('fetchGroup', orderData.groupId)

        if (getters.groups[orderData.groupId].courseId && !getters.courses[getters.groups[orderData.groupId].courseId]) {
          await dispatch('fetchCourse', getters.groups[orderData.groupId].courseId)
        }
      }

      orderData.orderId = orderId

      return orderData
    },
    async createOrder({ commit, dispatch }, orderData) {
      if (!orderData || !orderData.timestamp || !orderData.orderStudentId || !orderData.studentId || !orderData.groupId || !orderData.status) {
        commit('setError', 'Ошибка создания заказа')
        return
      }

      orderData.updated = orderData.timestamp

      try {
        const batch = writeBatch(getFirestore())

        const orderId = doc(collection(getFirestore(), 'orders')).id
        const orderRef = doc(getFirestore(), `orders/${orderId}`)
        batch.set(orderRef, orderData)

        const orderStatusId = doc(collection(getFirestore(), `orders/${orderId}/statuses`)).id
        const orderStatusRef = doc(getFirestore(), `orders/${orderId}/statuses/${orderStatusId}`)
        const statusData = {
          status: orderData.status,
          timestamp: orderData.timestamp,
          sum: 0
        }
        if (orderData.orderPrice !== undefined && orderData.orderPrice !== null) {
          statusData.sum = +orderData.orderPrice
        }

        batch.set(orderStatusRef, statusData)

        await batch.commit()

        orderData.orderId = orderId

        const answer = await dispatch('orderFetched', { orderData: orderData, orderId: orderId })
        await commit('setOrder', answer)
        await commit('addOrderStatuses', { orderId, status: statusData })
        commit('resetOrders')

        return orderId
      } catch (e) {
        commit('setError', e)
      }
    },
    async editOrder({ dispatch, commit, getters }, { orderId, orderData }) {
      if (!orderData || !orderId) {
        commit('setError', 'Ошибка редактирования заказа')
        return
      }

      orderData.updated = new Date()

      try {
        const batch = writeBatch(getFirestore())

        const orderRef = doc(getFirestore(), `orders/${orderId}`)
        batch.update(orderRef, orderData)

        let groupIdMinusOneStudent
        let groupIdPlusOneStudent
        if (orderData.groupId) {
          const oldGroupId = getters.orders[orderId].groupId
          if (!oldGroupId) { return false }

          let oldGroup = getters.groups[oldGroupId]
          if (!oldGroup) { await dispatch('fetchGroup', oldGroupId) }
          oldGroup = getters.groups[oldGroupId]
          if (!oldGroup) {
            commit('setError', 'Ошибка получения информкции о группе')
            return false
          }

          if (getters.orders[orderId].status && getters.ordersStatuses[getters.orders[orderId].status] && getters.ordersStatuses[getters.orders[orderId].status].addStudent) {
            if (oldGroup.numberOfStudents) {
              const oldGroupRef = doc(getFirestore(), `groups/${oldGroupId}`)
              batch.update(oldGroupRef, {
                numberOfStudents: increment(-1)
              })

              groupIdMinusOneStudent = oldGroupId
            }

            let newGroup = getters.groups[orderData.groupId]
            if (!newGroup) { await dispatch('fetchGroup', orderData.groupId) }
            newGroup = getters.groups[orderData.groupId]
            if (!newGroup) {
              commit('setError', 'Ошибка получения информкции о группе')
              return false
            }

            const newGroupRef = doc(getFirestore(), `groups/${orderData.groupId}`)
            batch.update(newGroupRef, {
              numberOfStudents: increment(1)
            })

            groupIdPlusOneStudent = orderData.groupId
          }
        }

        await batch.commit()

        orderData.orderId = orderId

        await commit('updateOrder', { orderId: orderId, data: orderData })
        commit('resetOrders')

        if (groupIdMinusOneStudent) {
          const group = {
            groupId: groupIdMinusOneStudent,
            numberOfStudents: getters.groups[groupIdMinusOneStudent].numberOfStudents - 1
          }
          commit('setGroup', group)
          commit('resetGroups')
        }

        if (groupIdPlusOneStudent) {
          const group = {
            groupId: groupIdPlusOneStudent,
            numberOfStudents: getters.groups[groupIdPlusOneStudent].numberOfStudents + 1
          }
          commit('setGroup', group)
          commit('resetGroups')
        }

        return orderId
      } catch (e) {
        commit('setError', e)
      }
    },
    async saveOrderVisited({ commit }, { orderId, visited }) {
      if (![true, false].includes(visited) || !orderId) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const ref = doc(getFirestore(), `orders/${orderId}`)
        await updateDoc(ref, {
          visited: visited,
          updated: new Date()
        })
          .then(async () => {
            await commit('updateOrder', { orderId: orderId, data: { visited: visited } })
            commit('resetOrders')
          })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async addOrderStatus({ commit, getters }, { orderId, data }) {
      if (!data || !data.status || !data.timestamp || !orderId) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const batch = writeBatch(getFirestore())

        const statusId = doc(collection(getFirestore(), `orders/${orderId}/statuses`)).id
        const statusRef = doc(getFirestore(), `orders/${orderId}/statuses/${statusId}`)
        batch.set(statusRef, data)

        const orderRef = doc(getFirestore(), `orders/${orderId}`)
        batch.update(orderRef, {
          status: data.status,
          updated: new Date()
        })

        const groupId = getters.orders[orderId].groupId
        const group = getters.groups[groupId]
        if (groupId && data.status && getters.ordersStatuses[data.status]) {
          const groupRef = doc(getFirestore(), `groups/${groupId}`)

          if (getters.ordersStatuses[data.status].addStudent) {
            batch.update(groupRef, {
              numberOfStudents: increment(1)
            })
          } else if (getters.ordersStatuses[data.status].deleteStudent && group.numberOfStudents) {
            batch.update(groupRef, {
              numberOfStudents: increment(-1)
            })
          }
        }

        await batch.commit()

        await commit('updateOrder', { orderId: orderId, data: { status: data.status } })
        await commit('addOrderStatuses', { orderId, status: data })
        commit('resetOrders')

        if (groupId && data.status && getters.ordersStatuses[data.status]) {
          if (getters.ordersStatuses[data.status].addStudent) {
            await commit('setGroup', {
              groupId: groupId,
              numberOfStudents: +group.numberOfStudents + 1
            })
          } else if (getters.ordersStatuses[data.status].deleteStudent && group.numberOfStudents) {
            await commit('setGroup', {
              groupId: groupId,
              numberOfStudents: +group.numberOfStudents + -1
            })
          }

          commit('resetGroups')
        }

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    orders: s => s.orders,
    ordersReset: s => s.ordersReset,
    allOrdersFetched: s => s.allOrdersFetched,
    lastFetchedOrder: s => s.lastFetchedOrder,
    ordersArr: (s, getters) => searchStr => {
      if (getters.ordersReset) {
        //
      }

      let answer = []

      if (!s.orders) { return answer }

      const ordersIds = Object.keys(s.orders)

      for (const orderId of ordersIds) {
        const order = s.orders[orderId]
        answer.push(order)
      }

      if (searchStr) {
        answer = answer.filter(order => {
          return (
            (order.comment && order.comment.toLowerCase().includes(searchStr.toLowerCase())) ||
            (order.ticketName && order.ticketName.toLowerCase().includes(searchStr.toLowerCase())) ||
            (order.studentId && getters.students[order.studentId] && getters.students[order.studentId].email.toLowerCase().includes(searchStr.toLowerCase())) ||
            (order.orderStudentId && getters.students[order.orderStudentId] && getters.students[order.orderStudentId].email.toLowerCase().includes(searchStr.toLowerCase())) ||
            (order.groupId && order.groupId.toLowerCase().includes(searchStr.toLowerCase())) ||
            (order.groupId && getters.groups[order.groupId] && getters.groups[order.groupId].name.toLowerCase().includes(searchStr.toLowerCase())) ||
            (order.groupId && getters.groups[order.groupId] && getters.groups[order.groupId].courseId && getters.courses[getters.groups[order.groupId].courseId] && getters.courses[getters.groups[order.groupId].courseId].landingPage && getters.courses[getters.groups[order.groupId].courseId].landingPage.toLowerCase().includes(searchStr.toLowerCase())) ||
            (order.groupId && getters.groups[order.groupId] && getters.groups[order.groupId].courseId && getters.courses[getters.groups[order.groupId].courseId] && getters.courses[getters.groups[order.groupId].courseId].landingPageCreated && getters.courses[getters.groups[order.groupId].courseId].landingPageCreated.toLowerCase().includes(searchStr.toLowerCase())) ||
            (order.groupId && getters.groups[order.groupId] && getters.groups[order.groupId].courseId && getters.courses[getters.groups[order.groupId].courseId] && getters.courses[getters.groups[order.groupId].courseId].publicName && getters.courses[getters.groups[order.groupId].courseId].publicName.toLowerCase().includes(searchStr.toLowerCase())) ||
            (order.groupId && getters.groups[order.groupId] && getters.groups[order.groupId].courseId && getters.courses[getters.groups[order.groupId].courseId] && getters.courses[getters.groups[order.groupId].courseId].shortName && getters.courses[getters.groups[order.groupId].courseId].shortName.toLowerCase().includes(searchStr.toLowerCase())) ||
            (order.status && order.status.toLowerCase().includes(searchStr.toLowerCase()))
          )
        })
      }

      answer.sort((a, b) => {
        const startA = +new Date(a.timestamp)
        const startB = +new Date(b.timestamp)
        if (startA < startB) { return 1 }
        if (startA > startB) { return -1 }
        return 0
      })

      return answer
    },
    ordersArrByStudentId: (s, getters) => studentId => {
      if (getters.ordersReset) {
        //
      }

      const answer = []

      if (!studentId) { return answer }
      if (!s.orders) { return answer }

      const ordersIds = Object.keys(s.orders)
      for (const orderId of ordersIds) {
        const order = s.orders[orderId]
        if (order.studentId === studentId || order.orderStudentId === studentId) {
          answer.push(order)
        }
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          const startA = +a.timestamp
          const startB = +b.timestamp
          if (startA < startB) { return 1 }
          if (startA > startB) { return -1 }
          return 0
        })
      }

      return answer
    },
    ordersArrByOrderStudentId: (s, getters) => studentId => {
      if (getters.ordersReset) {
        //
      }

      const answer = []

      if (!studentId) { return answer }
      if (!s.orders) { return answer }

      const ordersIds = Object.keys(s.orders)
      for (const orderId of ordersIds) {
        const order = s.orders[orderId]
        if (order.orderStudentId === studentId) {
          answer.push(order)
        }
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          const startA = +a.timestamp
          const startB = +b.timestamp
          if (startA < startB) { return 1 }
          if (startA > startB) { return -1 }
          return 0
        })
      }

      return answer
    },
    ordersStatuses: () => {
      return {
        created: { idx: 1, name: 'Создан', color: 'gray', visitable: false, payable: false },
        booked: { idx: 2, name: 'Ожидает оплаты', color: 'yellow', visitable: false, payable: false },
        booked_ur: { idx: 3, name: 'Ожидает оплаты (ю.л.)', color: 'yellow', visitable: false, payable: false },
        ok: { idx: 4, name: 'ОК', color: 'blue', visitable: true, payable: true, addStudent: true },
        paid: { idx: 5, name: 'Оплачен', color: 'green', visitable: true, payable: true, addStudent: true },
        paid_ur: { idx: 6, name: 'Оплачен (ю.л.)', color: 'green', visitable: true, payable: true, addStudent: true },
        notpaid: { idx: 7, name: 'Не оплачен', color: 'gray', visitable: false, payable: false, deleteStudent: true },
        deleted: { idx: 8, name: 'Удален', color: 'gray', visitable: false, payable: false, deleteStudent: true },
        return_requested: { idx: 9, name: 'Запрошен возврат', color: 'brown', visitable: false, payable: false },
        return_by_student: { idx: 10, name: 'Отмена (студент)', color: 'brown', visitable: false, payable: false, deleteStudent: true },
        return_by_school: { idx: 11, name: 'Отмена (школа)', color: 'brown', visitable: false, payable: false, deleteStudent: true }
      }
    },
    orderPaid: (s, getters) => orderId => {
      if (getters.ordersReset) {
        //
      }

      let sum = 0

      if (orderId && s.orderStatuses[orderId]) {
        for (const status of s.orderStatuses[orderId]) {
          if (status.status === 'ok' && status.sum) {
            sum = sum + +status.sum
          } else if (status.status === 'paid' && status.sum) {
            sum = sum + +status.sum
          } else if (status.status === 'paid_ur' && status.sum) {
            sum = sum + +status.sum
          }
        }
      }

      return sum
    },
    ordersFilters: s => s.ordersFilters,
    ordersSettingsFiltered: (s, getters) => {
      if (getters.ordersFilters.start ||
        getters.ordersFilters.end ||
        getters.ordersFilters.status) { return true }
      return false
    },
    orderStatuses: s => s.orderStatuses,
    orderStatusesSorted: (s, getters) => orderId => {
      if (s.ordersReset) {
        //
      }

      if (!orderId) { return [] }

      const statuses = getters.orderStatuses[orderId]

      if (statuses && statuses.length > 1) {
        statuses.sort((a, b) => {
          const statusATimestamp = +new Date(a.timestamp)
          const statusBTimestamp = +new Date(b.timestamp)
          if (statusATimestamp > statusBTimestamp) { return -1 }
          if (statusATimestamp < statusBTimestamp) { return 1 }
          return 0
        })
      }

      return statuses
    },
    ordersUnsubscribe: s => s.ordersUnsubscribe,
    showEditOrder: s => s.showEditOrder,
    showOrderSettings: s => s.showOrderSettings,
    orderSearchStrOrderId: s => s.orderSearchStrOrderId,
    ordersSearchStr: s => s.ordersSearchStr
  }
}
