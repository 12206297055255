import { getFirestore, getDocs, doc, collection, query, where, orderBy, setDoc, updateDoc } from 'firebase/firestore'

export default {
  state: {
    tasks: null,
    allTasksFetched: false,
    showCreateTask: false,
    tasksSearchStr: ''
  },
  mutations: {
    setTasksSearchStr(state, value) {
      state.tasksSearchStr = value
    },
    setShowCreateTask(state, value) {
      state.showCreateTask = value
    },
    setTask(state, taskData) {
      if (!taskData || !taskData.taskId) {
        return
      }

      if (!state.tasks) { state.tasks = {} }

      if (!state.tasks[taskData.taskId]) { state.tasks[taskData.taskId] = {} }

      for (const i in Object.keys(taskData)) {
        const key = Object.keys(taskData)[i]
        if (taskData[key] !== null && taskData[key] !== undefined && typeof Object.keys(taskData[key]) === 'object' && taskData[key].toString() === '[object Object]') {
          for (const y in Object.keys(taskData[key])) {
            const subKey = Object.keys(taskData[key])[y]

            if (!state.tasks[taskData.taskId][key]) {
              state.tasks[taskData.taskId][key] = {}
            }

            state.tasks[taskData.taskId][key][subKey] = taskData[key][subKey]
          }
        } else {
          state.tasks[taskData.taskId][key] = taskData[key]
        }
      }
    },
    setTasksFetched(state) {
      state.tasks = {}
    },
    setAllTasksFetched(state) {
      state.allTasksFetched = true
    },
    clearInfo(state) {
      state.tasks = null
      state.allTasksFetched = false
    }
  },
  actions: {
    async fetchTasks({ commit }) {
      try {
        const ref = collection(getFirestore(), 'tasks')
        const q = query(ref, where('active', '==', true), orderBy('createdDate', 'asc'))
        await getDocs(q)
          .then(async tasks => {
            if (!tasks.empty) {
              tasks.forEach(async task => {
                if (task.exists()) {
                  const taskData = task.data()
                  if (taskData.createdDate && taskData.createdDate.toDate) {
                    taskData.createdDate = taskData.createdDate.toDate()
                  }
                  if (taskData.doneDate && taskData.doneDate.toDate) {
                    taskData.doneDate = taskData.doneDate.toDate()
                  }
                  taskData.taskId = task.id
                  await commit('setTask', taskData)
                }
              })
            }

            await commit('setAllTasksFetched')
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async createTask({ dispatch, commit }, taskData) {
      if (!taskData || !taskData.type) {
        commit('setError', 'Невозможно создать задачу')
        return
      }
      const uid = await dispatch('getUid')

      taskData.createdUserId = uid
      taskData.createdDate = new Date()
      taskData.active = true

      try {
        const taskId = doc(collection(getFirestore(), 'tasks')).id
        const ref = doc(getFirestore(), `tasks/${taskId}`)
        await setDoc(ref, taskData)
          .then(async () => {
            taskData.taskId = taskId
            await commit('setTask', taskData)
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async markDeleted({ dispatch, commit }, taskId) {
      if (!taskId) {
        commit('setError', 'Не указана задача')
        return false
      }
      const uid = await dispatch('getUid')

      const taskData = {
        deletedUserId: uid,
        deletedDate: new Date(),
        active: false
      }

      try {
        await dispatch('updateTaskInfoToDB', { taskId: taskId, taskData: taskData })
      } catch (e) {
        commit('setError', e)
        return false
      }
      return true
    },
    async createNewTextTask({ dispatch, commit }, text) {
      if (!text) {
        commit('setError', 'Не указан текст задачи')
        return false
      }
      const uid = await dispatch('getUid')

      const taskData = {
        createdUserId: uid,
        createdDate: new Date(),
        active: true,
        type: 'text',
        data: {
          text: text
        }
      }

      try {
        const newTaskId = doc(collection(getFirestore(), 'tasks')).id
        const ref = doc(getFirestore(), `tasks/${newTaskId}`)
        await setDoc(ref, taskData)
          .then(async () => {
            taskData.taskId = newTaskId
            await commit('setTask', taskData)
          })
      } catch (e) {
        commit('setError', e)
        return false
      }
      return true
    },
    async updateTaskInfoToDB({ commit }, { taskId, taskData }) {
      if (!taskId || !taskData) {
        commit('setError', 'Не указана задача')
        return false
      }

      const ref = doc(getFirestore(), `tasks/${taskId}`)
      await updateDoc(ref, taskData)
        .then(async () => {
          taskData.taskId = taskId
          await commit('setTask', taskData)
        })
    },
    async executeTask({ dispatch, commit, getters }, taskId) {
      if (!taskId) {
        commit('setError', 'Не указана задача')
        return false
      }
      const uid = await dispatch('getUid')

      const task = getters.tasks[taskId]

      if (!task.type || !task.data) {
        commit('setError', 'Не хватает данных для выполнения задачи')
        return false
      }

      const taskData = {
        doneUserId: uid,
        doneDate: new Date(),
        active: false
      }

      try {
        if (task.type !== 'text') {
          await dispatch(task.type, task.data)
            .then(async () => {
              await dispatch('updateTaskInfoToDB', { taskId: taskId, taskData: taskData })
            })
        } else {
          await dispatch('updateTaskInfoToDB', { taskId: taskId, taskData: taskData })
        }

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    tasks: s => s.tasks,
    allTasksFetched: s => s.allTasksFetched,
    showCreateTask: s => s.showCreateTask,
    tasksSearchStr: s => s.tasksSearchStr
  }
}
