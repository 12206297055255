import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'
import store from '../store'
import { setPageTitle } from '@/helpers/page-meta'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'empty', title: '' },
    component: () => import('./../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'empty', title: 'Авторизация' },
    component: () => import('./../views/Login.vue')
  },
  {
    path: '/places-to-share',
    name: 'PlacesToShare',
    meta: { layout: 'main', auth: true, title: 'Места размещения' },
    component: () => import('./../views/PlacesToShare.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: { layout: 'main', auth: true, title: 'Настройки' },
    component: () => import('./../views/Settings.vue')
  },
  {
    path: '/tasks',
    name: 'Tasks',
    meta: { layout: 'main', auth: true, title: 'Задачи' },
    component: () => import('./../views/Tasks.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: { layout: 'main', auth: true, title: 'Заказы' },
    component: () => import('./../views/Orders.vue')
  },
  {
    path: '/orders/:orderId/view',
    name: 'OrderView',
    props: true,
    meta: { layout: 'main', auth: true, title: 'Заказ' },
    component: () => import('./../views/OrderView.vue')
  },
  {
    path: '/students',
    name: 'Students',
    meta: { layout: 'main', auth: true, title: 'Студенты' },
    component: () => import('./../views/Students.vue')
  },
  {
    path: '/students/:studentId/view',
    name: 'StudentView',
    props: true,
    meta: { layout: 'main', auth: true, title: 'Студент' },
    component: () => import('./../views/StudentView.vue')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    meta: { layout: 'main', auth: true, title: 'Обратная связь' },
    component: () => import('./../views/Feedback.vue')
  },
  {
    path: '/courses',
    name: 'Courses',
    meta: { layout: 'main', auth: true, title: 'Курсы' },
    component: () => import('./../views/Courses.vue')
  },
  {
    path: '/course/:courseId/view',
    name: 'CourseView',
    props: true,
    meta: { layout: 'main', auth: true, title: 'Курс' },
    component: () => import('./../views/CourseView.vue')
  },
  {
    path: '/course/:courseId/edit',
    name: 'CourseEdit',
    props: true,
    meta: { layout: 'main', auth: true, title: 'Курс' },
    component: () => import('./../views/CourseEdit.vue')
  },
  { path: '/course/:id', redirect: { name: 'CourseView' } },
  {
    path: '/groups',
    name: 'Groups',
    meta: { layout: 'main', auth: true, title: 'Расписание' },
    component: () => import('./../views/Groups.vue')
  },
  {
    path: '/group/:id/edit',
    name: 'GroupEdit',
    props: true,
    meta: { layout: 'main', auth: true, title: 'Группа' },
    component: () => import('./../views/GroupEdit.vue')
  },
  { path: '/group', redirect: { name: 'Groups' } },
  {
    path: '/stats',
    name: 'Stats',
    meta: { layout: 'main', auth: true, title: 'Статистика' },
    component: () => import('./../views/Stats.vue')
  },
  {
    path: '/headers',
    name: 'Headers',
    meta: { layout: 'main', auth: true, title: 'Хедеры' },
    component: () => import('./../views/Headers.vue')
  },
  {
    path: '/header/:headerId',
    name: 'Header',
    props: true,
    meta: { layout: 'main', auth: true, title: 'Хедер' },
    component: () => import('./../views/Header.vue')
  },
  {
    path: '/categories',
    name: 'Categories',
    meta: { layout: 'main', auth: true, title: 'Категории' },
    component: () => import('./../views/Categories.vue')
  },
  {
    path: '/category/:categoryId',
    name: 'Category',
    props: true,
    meta: { layout: 'main', auth: true, title: 'Категория' },
    component: () => import('./../views/Category.vue')
  },
  {
    path: '/timetable',
    name: 'Timetable',
    meta: { layout: 'main', auth: true, title: 'Расписание' },
    component: () => import('./../views/Timetable.vue')
  },
  {
    path: '/courses-table',
    name: 'CoursesTable',
    meta: { layout: 'main', auth: true, title: 'Таблица со списком всех курсов' },
    component: () => import('./../views/CoursesTable.vue')
  },
  {
    path: '/links',
    name: 'Links',
    meta: { layout: 'main', auth: true, title: 'Ссылки' },
    component: () => import('./../views/Links.vue')
  },
  {
    path: '/clicks/:linkId?',
    name: 'Clicks',
    meta: { layout: 'main', auth: true, title: 'Клики' },
    component: () => import('./../views/Clicks.vue')
  },
  {
    path: '/useful',
    name: 'Useful',
    meta: { layout: 'main', auth: true, title: 'Полезные ссылки' },
    component: () => import('./../views/Useful.vue')
  },
  {
    path: '/send-email',
    name: 'SendEmail',
    meta: { layout: 'main', auth: true, title: 'Отправить письмо' },
    component: () => import('./../views/SendEmail.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  setPageTitle(to.meta.title)

  const currentUser = getAuth().currentUser
  const requireAuth = to.matched.some(record => record.meta.auth)

  if (requireAuth && !currentUser) {
    store.commit('setOpenedLink', to.fullPath)
    next('/login?message=firstlogin')
    return
  } else {
    next()
    return
  }
})

export default router
