<template>
  <div>
    <div id="protector">
      <a href="/" target="_blank">Перейти на сайт</a>
    </div>

    <component :is="layout">
      <router-view />
    </component>
  </div>

  <AppNotifications />
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'
import errorCodes from '@/utils/error.codes'
import useNotifications from '@/composables/useNotifications'
import AppNotifications from '@/components/app/UI/AppNotifications'

export default {
  components: {
    EmptyLayout, MainLayout, AppNotifications
  },
  setup () {
    const { toastify } = useNotifications()
    return { toastify }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
    error () {
      return this.$store.getters.error
    }
  },
  watch: {
    error (err) {
      if (!err) { return }

      if (err.code && errorCodes[err.code]) {
        this.toastify.error(errorCodes[err.code])
      } else {
        this.toastify.error('[Ошибка]: ' + err)
      }

      console.log(err)
    }
  },
  async mounted() {
    if (top.document.location.hostname == document.location.hostname) {
      const protector = document.getElementById('protector')
      if (protector && protector.remove) {
        protector.remove()
      }
    }
  }
}
</script>

<style>
input[type='number'] {
  -moz-appearance:textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

#protector {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99999999;
    background-color: white;
  }
</style>
