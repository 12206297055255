export default {
  'auth/user-not-found': 'Пользователь с таким email не зарегистрирован',
  'auth/wrong-password': 'Неверный пароль',
  'auth/email-already-in-use': 'Такой email уже зарегистрирован',
  'auth/invalid-email': 'Проверьте привальность написания email',
  'auth/argument-error': 'Проверьте правильность заполнения полей',
  'auth/too-many-requests': 'Слишком много запросов. Попробуйте позже',
  'permission-denied': 'Нет доступа',
  unavailable: 'Нет доступа к интернету. Обновите страницу после восстановления соединения'
}
