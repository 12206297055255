import { getFirestore, getDoc, getDocs, doc, collection, query, writeBatch } from 'firebase/firestore'

export default {
  state: {
    headers: {},
    headersReset: 0,
    allHeadersFetched: false,
    headersSort: {
      field: 'fullName',
      direction: 'asc'
    }
  },
  mutations: {
    async setHeader(state, headerInfo) {
      if (!headerInfo || !headerInfo.headerId) {
        return
      }

      if (!state.headers[headerInfo.headerId]) {
        state.headers[headerInfo.headerId] = {}
      }

      for (const i in Object.keys(headerInfo)) {
        state.headers[headerInfo.headerId][Object.keys(headerInfo)[i]] = headerInfo[Object.keys(headerInfo)[i]]
      }
    },
    resetHeaders(state) {
      state.headersReset = +new Date()
    },
    async setAllHeadersFetched(state) {
      state.allHeadersFetched = true
    },
    setHeadersSordDirection(state, direction) {
      state.headersSort.direction = direction
    },
    setHeadersSordField(state, field) {
      state.headersSort.field = field
    },
    clearHeadersSort(state) {
      state.headersSort = {
        field: 'fullName',
        direction: 'asc'
      }
    },
    clearInfo(state) {
      state.headers = {}
      state.allHeadersFetched = false
      state.headersSort = {
        field: 'fullName',
        direction: 'asc'
      }
    }
  },
  actions: {
    async fetchAllHeaders({ commit }) {
      try {
        const ref = collection(getFirestore(), 'headers')
        const q = query(ref)
        await getDocs(q)
          .then(async headers => {
            if (!headers.empty) {
              headers.forEach(async header => {
                if (header.exists()) {
                  let headerData = {}
                  headerData = header.data()
                  headerData.headerId = header.id
                  await commit('setHeader', headerData)
                }
              })
              await commit('setAllHeadersFetched')
              commit('resetHeaders')
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchHeader({ commit }, headerId) {
      if (!headerId) {
        return
      }

      try {
        await getDoc(doc(getFirestore(), `headers/${headerId}`))
          .then(async header => {
            if (header.exists()) {
              let headerData = {}
              headerData = header.data()
              headerData.headerId = header.id
              await commit('setHeader', headerData)
              commit('resetHeaders')
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async saveHeaderInfo({ commit }, headerInfo) {
      if (!headerInfo || !headerInfo.headerId) {
        commit('setError', 'Ошибка')
        return false
      }

      let headerId = headerInfo.headerId

      try {
        if (headerInfo.headerId === 'new') {
          headerId = doc(collection(getFirestore(), 'headers')).id
        }

        const batch = writeBatch(getFirestore())

        const headerData = {}
        if (headerInfo.fullName) {
          headerData.fullName = headerInfo.fullName
        }
        if (headerInfo.name) {
          headerData.name = headerInfo.name
        }
        if (headerInfo.headerPageCreated !== undefined) {
          headerData.headerPageCreated = headerInfo.headerPageCreated
        }
        if (headerInfo.category) {
          headerData.category = headerInfo.category
        }
        if (headerInfo.banner) {
          headerData.banner = headerInfo.banner
        }
        if (headerInfo.logoSmallUrl) {
          headerData.logoSmallUrl = headerInfo.logoSmallUrl
        }

        if (Object.keys(headerData).length) {
          const headerRef = doc(getFirestore(), `headers/${headerId}`)
          if (headerInfo.headerId === 'new') {
            batch.set(headerRef, headerData)
          } else {
            batch.update(headerRef, headerData)
          }

          await batch.commit().then(async () => {
            headerData.headerId = headerId
            await commit('setHeader', headerData)
            commit('resetHeaders')
          })

          return headerId
        }
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    headers: s => s.headers,
    headersArray: s => {
      if (s.headersReset) {
        //
      }

      let arr = []

      const allHeaders = Object.keys(s.headers)
      if (allHeaders && allHeaders.length) {
        arr = allHeaders.slice(0)
      }

      if (arr.length > 1) {
        if (s.headersSort.field === 'fullName' || !s.headersSort.field) {
          arr.sort((a, b) => {
            const nameA = s.headers[a].fullName ? s.headers[a].fullName.toLowerCase() : ''
            const nameB = s.headers[b].fullName ? s.headers[b].fullName.toLowerCase() : ''

            if (nameA < nameB) { return -1 }
            if (nameA > nameB) { return 1 }
            return 0
          })
        } else if (s.headersSort.field === 'url') {
          arr.sort((a, b) => {
            const nameA = s.headers[a].name ? s.headers[a].name.toLowerCase() : ''
            const nameB = s.headers[b].name ? s.headers[b].name.toLowerCase() : ''

            if (nameA < nameB) { return -1 }
            if (nameA > nameB) { return 1 }
            return 0
          })
        }

        if (s.headersSort.direction && s.headersSort.direction === 'desc') {
          arr.reverse()
        }
      }

      return arr
    },
    allHeadersFetched: s => s.allHeadersFetched,
    headersSort: s => s.headersSort
  }
}
