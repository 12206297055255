<template>
  <div class="flex place-content-center pt-20">
    <div class="loader-appear">
      <div
        class="loading first"
        style="width: 30px; height: 30px; margin-right: 3px;"
      />

      <div
        class="loading second"
        style="width: 30px; height: 30px; margin-right: 3px;"
      />

      <div
        class="loading first"
        style="width: 30px; height: 30px; margin-right: 3px;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderComponent'
}
</script>

<style scoped>
  .loading {
    display: inline-block;
    background: #fff;
    border-radius: 50%;
  }

  .first {
    animation: first 0.9s infinite linear;
  }

  .second {
    animation: second 0.9s infinite linear;
  }

  @keyframes first {
    0% {
      opacity: 0.5;
      transform: scale(0.75);
    }

    50% {
      opacity: 0.9;
      transform: scale(1);
    }

    100% {
      opacity: 0.5;
      transform: scale(0.75);
    }
  }

  @keyframes second {
    0% {
      opacity: 0.9;
      transform: scale(1);
    }

    50% {
      opacity: 0.5;
      transform: scale(0.75);
    }

    100% {
      opacity: 0.9;
      transform: scale(1);
    }
  }
</style>
