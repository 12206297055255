import { getFirestore, getDocs, setDoc, doc, collection, query, where, orderBy, updateDoc } from 'firebase/firestore'

export default {
  state: {
    placesToShare: {},
    placesToShareReset: 0,
    fetchedArchived: false
  },
  mutations: {
    setPlaceToShare(state, placeData) {
      if (!placeData || !placeData.placeId) {
        return
      }

      state.placesToShare[placeData.placeId] = placeData
    },
    setFetchedArchived(state) {
      state.fetchedArchived = true
    },
    resetPlacesToShare(state) {
      state.placesToShareReset = Date.now()
    },
    clearInfo(state) {
      state.placesToShare = {}
      state.fetchedArchived = false
    }
  },
  actions: {
    async fetchPlacesToShare({ commit }, showArchived) {
      if (showArchived === undefined) { showArchived = true }

      try {
        const ref = collection(getFirestore(), 'placesToShare')
        const q = query(ref, where('active', '==', !showArchived), orderBy('timestamp', 'asc'))
        await getDocs(q)
          .then(async places => {
            if (!places.empty) {
              places.forEach(async place => {
                if (place.exists()) {
                  const placeData = place.data()
                  if (placeData.timestamp && placeData.timestamp.toDate) {
                    placeData.timestamp = placeData.timestamp.toDate()
                  }

                  placeData.placeId = place.id
                  await commit('setPlaceToShare', placeData)
                }
              })

              if (showArchived) {
                commit('setFetchedArchived')
              }

              commit('resetPlacesToShare')
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async savePlaceToShare({ commit }, { placeId, name, url }) {
      if (!name && !url) {
        commit('setError', 'Недостаточно данных')
        return false
      }

      try {
        if (!placeId) {
          placeId = doc(collection(getFirestore(), 'placesToShare')).id
        }

        const placeData = {
          timestamp: new Date(),
          active: true,
          name: name,
          url: url
        }

        const ref = doc(getFirestore(), `placesToShare/${placeId}`)
        await setDoc(ref, placeData)
          .then(async () => {
            placeData.placeId = placeId
            await commit('setPlaceToShare', placeData)
            await commit('resetPlacesToShare')
          })

        return placeId
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async markPlaceArchived({ commit, getters }, placeId) {
      if (!placeId) {
        commit('setError', 'Не указан ID')
        return false
      }

      const oldData = getters.placesToShare[placeId]

      try {
        const ref = doc(getFirestore(), `placesToShare/${placeId}`)

        await updateDoc(ref, {
          active: !oldData.active
        })
          .then(async () => {
            oldData.active = !oldData.active
            await commit('setPlaceToShare', oldData)
            await commit('resetPlacesToShare')
          })
      } catch (e) {
        commit('setError', e)
        return false
      }
      return true
    }
  },
  getters: {
    placesToShare: s => s.placesToShare,
    placesToShareReset: s => s.placesToShareReset,
    placesToShareArr: (s, getters) => showArchived => {
      if (getters.placesToShareReset) {
        //
      }

      let ids = Object.keys(s.placesToShare)

      if (!showArchived) {
        ids = ids.filter(id => {
          return s.placesToShare[id].active === true
        })
      }

      if (ids.length && ids.length > 1) {
        ids.sort((a, b) => {
          const nameA = s.placesToShare[a].name || a
          const nameB = s.placesToShare[b].name || b
          if (nameA < nameB) { return -1 }
          if (nameA > nameB) { return 1 }
          return 0
        })
      }

      return ids
    }
  }
}
