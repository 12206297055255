import { getFirestore, doc, updateDoc } from 'firebase/firestore'

export default {
  state: {
    yandexMetrikaStats: {},
    yandexMetrikaPrevStats: {},
    googleAnalyticsStats: {},
    googleAnalyticsPrevStats: {},
    youTubeStats: {}
  },
  mutations: {
    setYandexMetrikaStats(state, info) {
      state.yandexMetrikaStats = info
    },
    setYandexMetrikaPrevStats(state, info) {
      state.yandexMetrikaPrevStats = info
    },
    setGoogleAnalyticsStats(state, info) {
      state.googleAnalyticsStats = info
    },
    setGoogleAnalyticsPrevStats(state, info) {
      state.googleAnalyticsPrevStats = info
    },
    setYouTubeStats(state, info) {
      state.youTubeStats = info
    },
    clearYandexMetrikaStats(state) {
      state.yandexMetrikaStats = {}
      state.yandexMetrikaPrevStats = {}
    },
    clearGoogleAnaliticsStats(state) {
      state.googleAnalyticsStats = {}
      state.googleAnalyticsPrevStats = {}
    },
    clearYouTubeStats(state) {
      state.youTubeStats = {}
    },
    clearInfo(state) {
      state.yandexMetrikaStats = {}
      state.yandexMetrikaPrevStats = {}
      state.googleAnalyticsStats = {}
      state.googleAnalyticsPrevStats = {}
      state.youTubeStats = {}
    }
  },
  actions: {
    async createYandexMetrikaAppAccess({ commit }, data) {
      if (!data || !data.accessToken || !data.expires) {
        return
      }

      try {
        const ref = doc(getFirestore(), 'secrets/yandex-metrika')
        await updateDoc(ref, data)
      } catch (e) {
        commit('setError', e)
      }
    }
  },
  getters: {
    yandexMetrikaStats: s => s.yandexMetrikaStats,
    yandexMetrikaPrevStats: s => s.yandexMetrikaPrevStats,
    googleAnalyticsStats: s => s.googleAnalyticsStats,
    googleAnalyticsPrevStats: s => s.googleAnalyticsPrevStats,
    youTubeStats: s => s.youTubeStats,
  }
}
