import { getFirestore, getDoc, getDocs, doc, collection, query, updateDoc, where, orderBy } from 'firebase/firestore'
import dateFilter from '@/filters/date.filter'

export default {
  state: {
    formats: {
      webinar: 'вебинар',
      online: 'online',
      presence: 'очный'
    },
    groupTypes: {
      private: 'частная',
      public: 'общедоступная',
      corp: 'корпоративная',
      draft: 'черновик'
    },
    levels: {},
    allLevelsFetched: false,
    tarifs: {},
    allTarifsFetched: false,
    timeZone: null,
    timeZoneNotifications: null,
    blockedTimes: [],
    blockedTimesFetched: false,
  },
  mutations: {
    async setLevel(state, levelInfo) {
      if (!levelInfo || !levelInfo.id) {
        return
      }

      if (!state.levels[levelInfo.id]) {
        state.levels[levelInfo.id] = {}
      }

      for (const i in Object.keys(levelInfo)) {
        state.levels[levelInfo.id][Object.keys(levelInfo)[i]] = levelInfo[Object.keys(levelInfo)[i]]
      }
    },
    async setAllLevelsFetched(state) {
      state.allLevelsFetched = true
    },
    async setTarif(state, tarifInfo) {
      if (!tarifInfo || !tarifInfo.id) {
        return
      }

      if (!state.tarifs[tarifInfo.id]) {
        state.tarifs[tarifInfo.id] = {}
      }

      for (const i in Object.keys(tarifInfo)) {
        state.tarifs[tarifInfo.id][Object.keys(tarifInfo)[i]] = tarifInfo[Object.keys(tarifInfo)[i]]
      }
    },
    async setAllTarifsFetched(state) {
      state.allTarifsFetched = true
    },
    setTimezone(state, timezone) {
      state.timeZone = timezone
    },
    setTimezoneNotifications(state, timezone) {
      state.timeZoneNotifications = timezone
    },
    setBlockedTimes(state, time) {
      state.blockedTimes.push(time)
      state.blockedTimes.sort((a, b) => {
        if (+a.start < +b.start) { return -1 }
        if (+a.start > +b.start) { return 1 }
        return 0
      })
    },
    async setBlockedTimesFetched(state) {
      state.blockedTimesFetched = true
    },
    clearInfo(state) {
      state.levels = {}
      state.allLevelsFetched = false
      state.tarifs = {}
      state.allTarifsFetched = false
      state.timeZone = null
      state.blockedTimes = []
      state.blockedTimesFetched = false
    }
  },
  actions: {
    async fetchAllLevels({ commit }) {
      try {
        const ref = collection(getFirestore(), 'levels')
        const q = query(ref)
        await getDocs(q)
          .then(async levels => {
            if (!levels.empty) {
              levels.forEach(async level => {
                if (level.exists()) {
                  let levelData = {}
                  levelData = level.data()
                  levelData.id = level.id
                  await commit('setLevel', levelData)
                }
              })
              await commit('setAllLevelsFetched')
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchLevel({ commit }, levelId) {
      if (!levelId) {
        return
      }

      try {
        await getDoc(doc(getFirestore(), `levels/${levelId}`))
          .then(async level => {
            if (level.exists()) {
              let levelData = {}
              levelData = level.data()
              levelData.id = level.id
              await commit('setLevel', levelData)
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchTarif({ commit }, tarifId) {
      if (!tarifId) {
        return
      }

      try {
        await getDoc(doc(getFirestore(), `tarifs/${tarifId}`))
          .then(async tarif => {
            if (tarif.exists()) {
              let tarifData = {}
              tarifData = tarif.data()
              tarifData.id = tarif.id
              await commit('setTarif', tarifData)
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchAllTarifs({ commit }) {
      try {
        const ref = collection(getFirestore(), 'tarifs')
        const q = query(ref)
        await getDocs(q)
          .then(async tarifs => {
            if (!tarifs.empty) {
              tarifs.forEach(async tarif => {
                if (tarif.exists()) {
                  let tarifData = {}
                  tarifData = tarif.data()
                  tarifData.id = tarif.id
                  await commit('setTarif', tarifData)
                }
              })
              await commit('setAllTarifsFetched')
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchTimezone({ commit, getters }) {
      if (getters.timeZone && getters.timeZoneNotifications) { return }

      try {
        const tz = await getDoc(doc(getFirestore(), 'settings/timezones'))
        if (!tz || !tz.exists()) { return false }
        const tzData = tz.data()
        if (!tzData) { return false }

        if (tzData.notifications) {
          commit('setTimezoneNotifications', tzData.notifications)
        }

        if (!tzData.view) { return false }
        commit('setTimezone', tzData.view)
        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async fetchBlockedTimes({ commit, getters }) {
      try {
        if (getters.blockedTimesFetched) { return }

        const ref = collection(getFirestore(), 'blockedTimes')
        const q = query(ref, where('start', '>=', new Date()), orderBy('start', 'desc'), orderBy('timestamp', 'desc'))
        const bt = await getDocs(q)

        if (bt && !bt.empty) {
          for (const btDoc of bt.docs) {
            const btData = btDoc.data()
            if (btData.start && btData.start.toDate) { btData.start = btData.start.toDate() }
            if (btData.end && btData.end.toDate) { btData.end = btData.end.toDate() }
            await commit('setBlockedTimes', btData)
          }
        }

        await commit('setBlockedTimesFetched')
      } catch (e) {
        commit('setError', e)
      }
    },
    async saveTimeZone({ commit }, { timezoneName, location }) {
      if (!timezoneName || !location) { return }

      try {
        const timezonesRef = doc(getFirestore(), 'settings/timezones')

        const data = {}

        if (location === 'settings') {
          data.view = timezoneName
        } else if (location === 'notifications') {
          data.notifications = timezoneName
        }

        await updateDoc(timezonesRef, data)

        if (location === 'settings') {
          commit('setTimezone', timezoneName)
        } else if (location === 'notifications') {
          commit('setTimezoneNotifications', timezoneName)
        }

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    formats: s => s.formats,
    levels: s => s.levels,
    allLevelsFetched: s => s.allLevelsFetched,
    tarifs: s => s.tarifs,
    allTarifsFetched: s => s.allTarifsFetched,
    timeZone: s => s.timeZone,
    timeZoneNotifications: s => s.timeZoneNotifications,
    timeZoneOffsetString: (s, getters) => {
      const date = new Date()

      const utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }))
      const usedTimezoneDate = new Date(date.toLocaleString('en-US', { timeZone: getters.timeZone }))

      const offset = utcDate.getTime() - usedTimezoneDate.getTime()

      const timezoneDiffereceMin = (offset / 1000 / 60) * -1

      let str = ''
      if (timezoneDiffereceMin > 0) { str = str + '+' }
      const hours = timezoneDiffereceMin / 60
      if (Math.abs(hours) >= 0) { str = str + Math.floor(hours) }
      if (timezoneDiffereceMin % 60) {
        str = str + '.' + Math.floor((timezoneDiffereceMin % 60) / 60)
      }
      str = str + ' UTC'

      return str
    },
    createTimezoneTextInfo: (s, getters) => {
      const answer = []

      if (getters.timeZone && getters.timeZoneOffsetString) {
        answer.push('Даты/время отображаются в часовом поясе:\n')
        answer.push(getters.timeZone + ' (' + getters.timeZoneOffsetString + ')')
      }

      const date = new Date()
      const computerTimeStr = dateFilter(date, 'onlyTime')
      if (answer.length) { answer.push('\n\n') }
      answer.push('Время компьютера сейчас: ' + computerTimeStr)

      const moscowDate = new Date(date.toLocaleString('en-US', { timeZone: 'Europe/Moscow' }))
      const moscowTimeStr = dateFilter(moscowDate, 'onlyTime')
      if (answer.length) { answer.push('\n') }
      answer.push('Время в Москве сейчас: ' + moscowTimeStr)

      const usedTimezoneDate = new Date(date.toLocaleString('en-US', { timeZone: getters.timeZone }))

      const differenceText = (firstTZDate, secondTZDate) => {
        if (!firstTZDate || !secondTZDate) { return '' }
        if (+firstTZDate === +secondTZDate) { return '' }

        const offset = firstTZDate.getTime() - secondTZDate.getTime()
        if (!offset) { return '' }

        const differenceInMinutes = Math.ceil(offset / 1000 / 60)
        const differenceInHours = differenceInMinutes / 60

        let text = ''
        if (differenceInHours >= 0) { text = text + Math.floor(differenceInHours) + ' ч.' }
        if ((differenceInMinutes % 60) > 0) { text = text + ' ' + (differenceInMinutes % 60) + ' мин.' }
        return text
      }

      if (differenceText(moscowDate, usedTimezoneDate)) {
        if (answer.length) { answer.push('\n\n') }
        answer.push('Разница между московским временем и временем в настройках: ')
        answer.push(differenceText(moscowDate, usedTimezoneDate))
      }

      if (differenceText(usedTimezoneDate, date)) {
        if (answer.length) { answer.push('\n\n') }
        answer.push('Разница между временем в настройках и временем компьютера: ')
        answer.push(differenceText(usedTimezoneDate, date))
      }

      return answer.join('')
    },
    blockedTimes: s => s.blockedTimes,
    blockedTimesFetched: s => s.blockedTimesFetched,
    groupTypes: s => s.groupTypes
  }
}
