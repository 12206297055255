export default function hoursToMins (duration) {
  if (!duration) { return '0 ч.' }
  if (duration < 60) { return '0 ч. ' + duration + ' мин.' }

  const hours = Math.floor(+duration / 60)
  const mins = +duration - (hours * 60)

  let answer = hours + 'ч.'

  if (mins) {
    answer = answer + ' ' + mins + ' мин.'
  }

  return answer
}
