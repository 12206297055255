import { getFirestore, doc, collection, query, where, limit, getDoc, getDocs, writeBatch, orderBy, startAfter, getCountFromServer, increment } from 'firebase/firestore'
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
const { nanoid } = require('nanoid')

export default {
  state: {
    shortLinks: {},
    allShortLinksFetched: false,
    lastFetchedShortLink: null,
    shortLinksReset: 0,
    badIncludes: null,
    badRequests: null,
    clicks: {},
    allClicksFetched: false,
    allClicksFetchedForLink: {},
    lastFetchedClick: null,
    lastFetchedClickForLink: {},
    clicksReset: 0,
    clicksTotal: 0,
    linksTotal: 0,
    pinnedShortLinksFetched: false,
    findShortUrlId: '',
    findFullUrl: '',
    filteredIp: null
  },
  mutations: {
    setFindShortUrl(state, value) {
      state.findShortUrlId = value
    },
    setFindFullUrl(state, value) {
      state.findFullUrl = value
    },
    setPinnedShortLinksFetched(state) {
      state.pinnedShortLinksFetched = true
    },
    setShortLink(state, { id, data }) {
      if (id && data) {
        data.id = id
        state.shortLinks[id] = data
      }
    },
    updateShortLink(state, { id, data }) {
      if (id && data) {
        for (const field of Object.keys(data)) {
          state.shortLinks[id][field] = data[field]
        }
      }
    },
    removeShortLink(state, linkId) {
      if (linkId && state.shortLinks[linkId]) {
        delete state.shortLinks[linkId]
      }
    },
    setLastShortLinkFetched(state, link) {
      state.lastFetchedShortLink = link
    },
    setAllShortLinksFetched(state, val) {
      state.allShortLinksFetched = val
    },
    resetShortLinks(state) {
      state.shortLinksReset = Date.now()
    },
    setBadIncludes(state, value) {
      state.badIncludes = value
    },
    setBadRequests(state, value) {
      state.badRequests = value
    },
    setAllClicksFetched(state, value) {
      state.allClicksFetched = value
    },
    setAllClicksFetchedForLink(state, { linkId, value }) {
      state.allClicksFetchedForLink[linkId] = value
    },
    addClickToClicks(state, click) {
      if (click && click.id) {
        state.clicks[click.id] = click
      }
    },
    removeClickFromClicks(state, clickId) {
      if (clickId && state.clicks[clickId]) {
        delete state.clicks[clickId]
      }

      if (state.clicksTotal) { state.clicksTotal = +state.clicksTotal - 1 }
    },
    resetClicks(state) {
      state.clicksReset = Date.now()
    },
    setLastFetchedClick(state, value) {
      state.lastFetchedClick = value
    },
    setLastFetchedClickForLink(state, { linkId, value }) {
      state.lastFetchedClickForLink[linkId] = value
    },
    setClicksTotal(state, total) {
      state.clicksTotal = total
    },
    setLinksTotal(state, total) {
      state.linksTotal = total
    },
    setFilteredIp(state, value) {
      state.filteredIp = value
    },
    clearInfo(state) {
      state.shortLinks = {}
      state.allShortLinksFetched = false
      state.badIncludes = null
      state.badRequests = null
      state.allClicksFetched = false
      state.allClicksFetchedForLink = {}
      state.lastFetchedClick = null
      state.lastFetchedClickForLink = {}
      state.clicksTotal = 0
      state.linksTotal = 0
      state.findShortUrlId = ''
      state.findFullUrl = ''
      state.filteredIp = null
    }
  },
  actions: {
    async checkIfShortIdExists(context, id) {
      if (!id) {
        throw new Error('Не указан id')
      }

      try {
        const answer = await getDoc(doc(getFirestore(), `shortLinks/${id}`))
        if (answer.exists()) { return true }
        return false
      } catch (e) {
        throw new Error(e)
      }
    },
    async findShortLinkByFullUrl(context, url) {
      if (!url) {
        throw new Error('Не указан url')
      }

      try {
        const shortLinksRef = collection(getFirestore(), 'shortLinks')
        const shortLinksQ = query(shortLinksRef, where('url', '==', url), limit(1))
        const answer = await getDocs(shortLinksQ)
        if (answer.empty) { return null }
        return answer.docs[0].id
      } catch (e) {
        throw new Error(e)
      }
    },
    async createShortLink({ commit, dispatch, getters }, url) {
      if (!url) {
        commit('setError', 'Не задан исходный url')
        return
      }

      try {
        let id = await dispatch('findShortLinkByFullUrl', url)

        if (!id) {
          id = nanoid(7)

          if (!getters.badRequests || !getters.badIncludes) {
            const functions = getFunctions(getApp())
            const getBadDataForShortLinks = httpsCallable(functions, 'getBadDataForShortLinks')
            const badData = await getBadDataForShortLinks()
            if (!badData || !badData.data) {
              await commit('setBadIncludes', [])
              await commit('setBadRequests', [])
            } else {
              if (badData.data.badIncludes) {
                await commit('setBadIncludes', badData.data.badIncludes)
              } else {
                await commit('setBadIncludes', [])
              }
              if (badData.data.badRequests) {
                await commit('setBadRequests', badData.data.badRequests)
              } else {
                await commit('setBadRequests', [])
              }
            }
          }

          for (const badId of getters.badIncludes) {
            if (id.toLowerCase().includes(badId, id.length - badId.length)) { return 'try-again' }
          }

          if (['-', '_'].includes(id.substring(id.length - 1))) { return 'try-again' }

          for (const badId of getters.badRequests) {
            if (badId.toLowerCase().includes(id.toLowerCase())) { return 'try-again' }
          }

          const idExists = await dispatch('checkIfShortIdExists', id)
          if (idExists) { return 'try-again' }

          const timestamp = new Date()

          const batch = writeBatch(getFirestore())

          const linkData = {
            url,
            timestamp,
            clicks: 0,
            pinned: false
          }

          const linkRef = doc(getFirestore(), `shortLinks/${id}`)
          batch.set(linkRef, linkData)

          const linkDataRef = doc(getFirestore(), `shortLinks/${id}/linkData/public`)
          batch.set(linkDataRef, {
            url: url
          })

          await batch.commit()

          await commit('setShortLink', { id, data: linkData })
          commit('resetShortLinks')
        }

        return id
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchShortLink({ commit }, id) {
      if (!id) { return }

      try {
        const link = await getDoc(doc(getFirestore(), `shortLinks/${id}`))

        if (link && link.exists()) {
          const linkData = link.data()
          if (linkData.timestamp && linkData.timestamp.toDate) { linkData.timestamp = linkData.timestamp.toDate() }
          await commit('setShortLink', { id: link.id, data: linkData })
          await commit('resetShortLinks')
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchShortLinks({ commit, getters }) {
      if (getters.allShortLinksFetched) { return }

      const maxLength = 10

      try {
        const ref = collection(getFirestore(), 'shortLinks')

        const queries = [orderBy('timestamp', 'desc'), limit(maxLength)]

        const lastFetchedShortLink = await getters.lastFetchedShortLink
        if (lastFetchedShortLink) {
          queries.push(startAfter(lastFetchedShortLink))
        }

        const q = query(ref, ...queries)

        const links = await getDocs(q)
        if (!links.empty) {
          for (const link of links.docs) {
            if (link.exists()) {
              const linkData = link.data()
              if (linkData.timestamp && linkData.timestamp.toDate) { linkData.timestamp = linkData.timestamp.toDate() }

              await commit('setShortLink', { id: link.id, data: linkData })
            }
          }

          await commit('resetShortLinks')

          await commit('setLastShortLinkFetched', links.docs[links.docs.length - 1])
        }

        if (links.empty || links.size < maxLength) {
          await commit('setAllShortLinksFetched', true)
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchPinnedShortLinks({ commit, getters }) {
      if (getters.pinnedShortLinksFetched) { return }

      try {
        const ref = collection(getFirestore(), 'shortLinks')
        const queries = [orderBy('timestamp', 'desc'), where('pinned', '==', true)]
        const q = query(ref, ...queries)

        const links = await getDocs(q)
        if (!links.empty) {
          for (const link of links.docs) {
            if (link.exists()) {
              const linkData = link.data()
              if (linkData.timestamp && linkData.timestamp.toDate) { linkData.timestamp = linkData.timestamp.toDate() }

              await commit('setShortLink', { id: link.id, data: linkData })
            }
          }

          await commit('resetShortLinks')
        }

        await commit('setPinnedShortLinksFetched')
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchLinksTotal({ commit, getters }) {
      if (getters.linksTotal) { return }

      try {
        const clicksRef = collection(getFirestore(), 'shortLinks')
        const snapshot = await getCountFromServer(clicksRef)
        commit('setLinksTotal', snapshot.data().count)
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchClicksTotal({ commit, getters }) {
      if (getters.clicksTotal) { return }

      try {
        const clicksRef = collection(getFirestore(), 'clicks')
        const snapshot = await getCountFromServer(clicksRef)
        commit('setClicksTotal', snapshot.data().count)
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchClicks({ commit, getters }, linkId) {
      if (getters.allClicksFetched) { return }
      if (linkId && getters.allClicksFetchedForLink[linkId]) { return }

      const maxLength = getters.filteredIp ? 50 : 10

      try {
        const clicksRef = collection(getFirestore(), 'clicks')

        const queries = [orderBy('timestamp', 'desc'), limit(maxLength)]

        if (linkId) {
          queries.push(where('linkId', '==', linkId))

          if (getters.lastFetchedClickForLink[linkId]) {
            queries.push(startAfter(getters.lastFetchedClickForLink[linkId]))
          }
        } else {
          if (getters.lastFetchedClick) {
            queries.push(startAfter(getters.lastFetchedClick))
          }
        }
        const q = query(clicksRef, ...queries)

        const clicks = await getDocs(q)
        if (clicks.empty) {
          if (linkId) {
            await commit('setAllClicksFetchedForLink', { linkId, value: true })
          } else {
            await commit('setAllClicksFetched', true)
          }
        } else {
          if (clicks.size < maxLength) {
            if (linkId) {
              await commit('setAllClicksFetchedForLink', { linkId, value: true })
            } else {
              await commit('setAllClicksFetched', true)
            }
          }

          for (const click of clicks.docs) {
            const clickData = click.data()
            if (clickData.timestamp && clickData.timestamp.toDate) { clickData.timestamp = clickData.timestamp.toDate() }
            clickData.id = click.id
            await commit('addClickToClicks', clickData)
          }

          if (linkId) {
            await commit('setLastFetchedClickForLink', { linkId, value: clicks.docs[clicks.docs.length - 1] })
          }
          await commit('setLastFetchedClick', clicks.docs[clicks.docs.length - 1])

          await commit('resetClicks')
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async saveLinkComment({ commit, getters }, { linkId, comment }) {
      if (!linkId || !getters.shortLinks[linkId]) {
        commit('setError', 'Нет данных о ссылке')
        return
      }

      if (getters.shortLinks[linkId].comment === comment) { return true }

      if (!comment) { comment = '' }

      try {
        const batch = writeBatch(getFirestore())

        const linkData = {
          comment: comment.trim()
        }

        const linkRef = doc(getFirestore(), `shortLinks/${linkId}`)
        batch.update(linkRef, linkData)

        await batch.commit()

        await commit('updateShortLink', { id: linkId, data: linkData })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async pinLink({ commit, getters }, linkId) {
      if (!linkId || !getters.shortLinks[linkId]) {
        commit('setError', 'Нет данных о ссылке')
        return
      }

      try {
        const batch = writeBatch(getFirestore())

        const linkData = {
          pinned: getters.shortLinks[linkId].pinned ? false : true
        }

        const linkRef = doc(getFirestore(), `shortLinks/${linkId}`)
        batch.update(linkRef, linkData)

        await batch.commit()

        await commit('updateShortLink', { id: linkId, data: linkData })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async deleteClick({ commit, getters }, clickId) {
      if (!clickId) {
        commit('setError', 'Нет указан clickId')
        return
      }

      const click = getters.clicks[clickId]

      if (!click) {
        commit('setError', 'Клик не найден')
        return
      }

      try {
        const batch = writeBatch(getFirestore())

        const clickRef = doc(getFirestore(), `clicks/${clickId}`)
        batch.delete(clickRef)

        if (click.linkId && getters.shortLinks[click.linkId]) {
          const linkRef = doc(getFirestore(), `shortLinks/${click.linkId}`)
          batch.update(linkRef, {
            clicks: increment(-1)
          })
        }

        await batch.commit()

        await commit('removeClickFromClicks', clickId)
        commit('resetClicks')

        if (click.linkId && getters.shortLinks[click.linkId] && +getters.shortLinks[click.linkId].clicks > 0) {
          await commit('updateShortLink', {
            id: click.linkId,
            data: {
              clicks: +getters.shortLinks[click.linkId].clicks - 1
            }
          })

          commit('resetShortLinks')
        }

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async deleteLink({ commit }, linkId) {
      if (!linkId) {
        commit('setError', 'Нет указан linkId')
        return
      }

      try {
        const batchArray = []
        batchArray.push(writeBatch(getFirestore()))
        let count = 0
        let batchIndex = 0
        const countLimit = 495

        const clicksIds = []
        const clicksRef = collection(getFirestore(), 'clicks')
        const clicksQ = query(clicksRef, where('linkId', '==', linkId))
        const clicks = await getDocs(clicksQ)
        if (clicks && !clicks.empty) {
          for (const click of clicks.docs) {
            const clickId = click.id
            if (!clicksIds.includes(clickId)) { clicksIds.push(clickId) }
          }
        }

        for (const clickId of clicksIds) {
          const clickRef = doc(getFirestore(), `clicks/${clickId}`)
          batchArray[batchIndex].delete(clickRef)
          count++
          if (count > countLimit) {
            batchArray.push(writeBatch(getFirestore()))
            batchIndex++
            count = 0
          }
        }

        const linkRef = doc(getFirestore(), `shortLinks/${linkId}`)
        batchArray[batchIndex].delete(linkRef)
        count++
        if (count > countLimit) {
          batchArray.push(writeBatch(getFirestore()))
          batchIndex++
          count = 0
        }

        const linkPublicDataRef = doc(getFirestore(), `shortLinks/${linkId}/linkData/public`)
        batchArray[batchIndex].delete(linkPublicDataRef)
        count++
        if (count > countLimit) {
          batchArray.push(writeBatch(getFirestore()))
          batchIndex++
          count = 0
        }

        for (const batch of batchArray) {
          await batch.commit()
        }

        for (const clickId of clicksIds) {
          await commit('removeClickFromClicks', clickId)
        }
        commit('resetClicks')

        await commit('removeShortLink', linkId)
        commit('resetShortLinks')

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    shortLinks: s => s.shortLinks,
    shortLinksArr: (s, getters) => {
      if (getters.shortLinksReset) {
        //
      }

      const answer = []

      let shortLinksIds = Object.keys(s.shortLinks)
      for (const shortLinksId of shortLinksIds) {
        const shortLink = s.shortLinks[shortLinksId]

        if (getters.findShortUrlId) {
          if (shortLinksId === getters.findShortUrlId) { answer.push(shortLink) }
        } else if (getters.findFullUrl) {
          if (shortLink.url.toLowerCase().includes(getters.findFullUrl.toLowerCase())) { answer.push(shortLink) }
        } else {
          answer.push(shortLink)
        }
      }

      if (answer.length > 1) {
        answer.sort((a, b) => {
          const startA = +new Date(a.timestamp)
          const startB = +new Date(b.timestamp)
          if (startA < startB) { return 1 }
          if (startA > startB) { return -1 }
          return 0
        })

        answer.sort((a, b) => {
          if (a.pinned == b.pinned) {
            const startA = +new Date(a.timestamp)
            const startB = +new Date(b.timestamp)
            if (startA < startB) { return 1 }
            if (startA > startB) { return -1 }
            return 0
          } else {
            if (a.pinned) { return -1 }
            if (b.pinned) { return 1 }
            return 0
          }
        })
      }

      return answer
    },
    clicksArr: (s, getters) => linkId => {
      if (getters.clicksReset) {
        //
      }

      let clicksIds = Object.keys(s.clicks)

      if (linkId) {
        clicksIds = clicksIds.filter(clicksId => {
          return getters.clicks[clicksId].linkId === linkId
        })
      }

      if (getters.filteredIp) {
        clicksIds = clicksIds.filter(clicksId => {
          return getters.clicks[clicksId].ip === getters.filteredIp
        })
      }

      if (clicksIds.length > 1) {
        clicksIds.sort((a, b) => {
          const startA = +new Date(a.timestamp)
          const startB = +new Date(b.timestamp)
          if (startA < startB) { return 1 }
          if (startA > startB) { return -1 }
          return 0
        })
      }

      return clicksIds
    },
    allShortLinksFetched: s => s.allShortLinksFetched,
    lastFetchedShortLink: s => s.lastFetchedShortLink,
    shortLinksReset: s => s.shortLinksReset,
    badIncludes: s => s.badIncludes,
    badRequests: s => s.badRequests,
    clicks: s => s.clicks,
    allClicksFetched: s => s.allClicksFetched,
    allClicksFetchedForLink: s => s.allClicksFetchedForLink,
    clicksReset: s => s.clicksReset,
    lastFetchedClick: s => s.lastFetchedClick,
    lastFetchedClickForLink: s => s.lastFetchedClickForLink,
    clicksTotal: s => s.clicksTotal,
    linksTotal: s => s.linksTotal,
    pinnedShortLinksFetched: s => s.pinnedShortLinksFetched,
    findShortUrlId: s => s.findShortUrlId,
    findFullUrl: s => s.findFullUrl,
    filteredIp: s => s.filteredIp
  }
}
