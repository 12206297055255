import { getFirestore, getDoc, doc, setDoc } from 'firebase/firestore'

export default {
  state: {
    timetable: null,
    table1: null
  },
  mutations: {
    async setTimetable(state, coursesArr) {
      if (coursesArr) {
        state.timetable = coursesArr
      }
    },
    async setTable1(state, coursesArr) {
      if (coursesArr) {
        state.table1 = coursesArr
      }
    },
    clearInfo(state) {
      state.timetable = null
      state.table1 = null
    }
  },
  actions: {
    async fetchTimetable({ commit }) {
      try {
        await getDoc(doc(getFirestore(), 'settings/timetable'))
          .then(async timetable => {
            if (timetable.exists() && timetable.data() && timetable.data().courses) {
              await commit('setTimetable', timetable.data().courses)
            } else {
              await commit('setTimetable', [])
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async saveTimetable({ commit }, coursesArr) {
      if (!coursesArr || !coursesArr.length) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const ref = doc(getFirestore(), 'settings/timetable')
        await setDoc(ref, {
          courses: coursesArr
        })
          .then(async () => {
            await commit('setTimetable', coursesArr)
          })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    },
    async fetchTable1({ commit }) {
      try {
        await getDoc(doc(getFirestore(), 'settings/table1'))
          .then(async table1 => {
            if (table1.exists() && table1.data() && table1.data().courses) {
              await commit('setTable1', table1.data().courses)
            } else {
              await commit('setTable1', [])
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async saveTable1({ commit }, coursesArr) {
      if (!coursesArr || !coursesArr.length) {
        commit('setError', 'Ошибка')
        return false
      }

      try {
        const ref = doc(getFirestore(), 'settings/table1')
        await setDoc(ref, {
          courses: coursesArr
        })
          .then(async () => {
            await commit('setTable1', coursesArr)
          })

        return true
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    timetable: s => s.timetable,
    table1: s => s.table1
  }
}
