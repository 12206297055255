export default function dateFilter(value, format = 'shortDate', showWeekDay = false) {
  const options = {}

  if (format.includes('shortDate')) {
    options.day = '2-digit'
    options.month = '2-digit'
    options.year = 'numeric'
  } else if (format.includes('dateAndTimeWithSeconds')) {
    options.day = '2-digit'
    options.month = '2-digit'
    options.year = 'numeric'

    options.hour = '2-digit'
    options.minute = '2-digit'
    options.second = '2-digit'
  } else if (format.includes('dateAndTime')) {
    options.day = '2-digit'
    options.month = '2-digit'
    options.year = 'numeric'

    options.hour = '2-digit'
    options.minute = '2-digit'
  } else if (format.includes('onlyTime')) {
    options.hour = '2-digit'
    options.minute = '2-digit'
  }

  if (showWeekDay) { options.weekday = 'short' }

  return new Intl.DateTimeFormat('RU-ru', options).format(new Date(value))
}
