import { getFirestore, getDoc, getDocs, doc, collection, query, writeBatch, where, orderBy, limit, increment } from 'firebase/firestore'

export default {
  state: {
    courses: {},
    coursesReset: 0,
    allActiveCoursesFetched: false,
    allArchievedCoursesFetched: false,
    showArchievedCourses: false,
    coursesSearchStr: '',
    coursesSort: {
      field: 'mainCategory',
      direction: 'asc'
    }
  },
  mutations: {
    setAllActiveCoursesFetched(state) {
      state.allActiveCoursesFetched = true
    },
    setAllArchievedCoursesFetched(state) {
      state.allArchievedCoursesFetched = true
    },
    resetCourses(state) {
      state.coursesReset = +new Date()
    },
    setShowArchievedCourses(state, value) {
      state.showArchievedCourses = value
    },
    setCoursesSearchStr(state, value) {
      state.coursesSearchStr = value
    },
    coursesSortDirection(state, value) {
      state.coursesSort.direction = value
    },
    coursesSortField(state, value) {
      state.coursesSort.field = value
    },
    setCourse(state, courseInfo) {
      if (!courseInfo || !courseInfo.courseId) {
        return
      }

      if (!state.courses[courseInfo.courseId]) {
        state.courses[courseInfo.courseId] = {}
      }

      for (const i in Object.keys(courseInfo)) {
        const key = Object.keys(courseInfo)[i]
        if (courseInfo[key] !== null && courseInfo[key] !== undefined && typeof Object.keys(courseInfo[key]) === 'object' && courseInfo[key].toString() === '[object Object]') {
          for (const y in Object.keys(courseInfo[key])) {
            const subKey = Object.keys(courseInfo[key])[y]

            if (!state.courses[courseInfo.courseId][key]) {
              state.courses[courseInfo.courseId][key] = {}
            }

            state.courses[courseInfo.courseId][key][subKey] = courseInfo[key][subKey]
          }
        } else {
          state.courses[courseInfo.courseId][key] = courseInfo[key]
        }
      }
    },
    clearCoursesSort(state) {
      state.coursesSort = {
        field: 'mainCategory',
        direction: 'asc'
      }
    },
    clearInfo(state) {
      state.courses = {}
      state.allActiveCoursesFetched = false
      state.allArchievedCoursesFetched = false
      state.showArchievedCourses = false
      state.coursesSearchStr = ''
      state.coursesSort = {
        field: 'mainCategory',
        direction: 'asc'
      }
    }
  },
  actions: {
    async fetchAllCoursesList({ dispatch, commit, getters }, { active }) {
      try {
        const coursesRef = collection(getFirestore(), 'courses')
        const coursesQ = query(coursesRef, where('active', '==', active), orderBy('shortName', 'asc'))
        const courses = await getDocs(coursesQ)

        if (courses && !courses.empty) {
          for (const course of courses.docs) {
            if (course.exists()) {
              const courseData = course.data()
              courseData.courseId = course.id
              await commit('setCourse', courseData)

              if (courseData.categories) {
                for (const categoryId of courseData.categories) {
                  if (!getters.categories[categoryId]) {
                    await dispatch('fetchCategory', categoryId)
                  }
                }
              }

              if (courseData.headers) {
                for (const headerId of courseData.headers) {
                  if (!getters.headers[headerId]) {
                    await dispatch('fetchHeader', headerId)
                  }
                }
              }
            }
          }
        }

        if (active) {
          await commit('setAllActiveCoursesFetched')
        } else {
          await commit('setAllArchievedCoursesFetched')
        }
        commit('resetCourses')
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchCourse({ getters, commit, dispatch }, courseId) {
      if (!courseId) {
        commit('setError', 'Ошибка')
        return
      }

      try {
        const course = await getDoc(doc(getFirestore(), `courses/${courseId}`))
        if (course.exists()) {
          const courseData = course.data()
          courseData.courseId = courseId
          await commit('setCourse', courseData)
          commit('resetCourses')

          if (courseData.categories) {
            for (const categoryId of courseData.categories) {
              if (!getters.categories[categoryId]) {
                await dispatch('fetchCategory', categoryId)
              }
            }
          }

          if (courseData.headers) {
            for (const headerId of courseData.headers) {
              if (!getters.headers[headerId]) {
                await dispatch('fetchHeader', headerId)
              }
            }
          }
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchWaitingForCourseActivation({ commit }, courseId) {
      if (!courseId) { return }

      try {
        const ids = []

        const ref = collection(getFirestore(), 'notifications')
        const q = query(ref, where('courseId', '==', courseId), where('type', '==', 'closedcourse'))
        const answer = await getDocs(q)
        if (answer && !answer.empty) {
          for (const notificationId of answer.docs) {
            if (!ids.includes(notificationId)) { ids.push(notificationId) }
          }

          return ids
        }

        return
      } catch (e) {
        commit('setError', e)
        return null
      }
    },
    async fetchWaitingForCourseGroups({ commit }, courseId) {
      if (!courseId) { return }

      try {
        const ids = []

        const ref = collection(getFirestore(), 'notifications')
        const q = query(ref, where('courseId', '==', courseId), where('type', '==', 'nogroupscourse'))
        const answer = await getDocs(q)
        if (answer && !answer.empty) {
          for (const notificationId of answer.docs) {
            if (!ids.includes(notificationId)) { ids.push(notificationId) }
          }

          return ids
        }

        return
      } catch (e) {
        commit('setError', e)
        return null
      }
    },
    async checkIfExists({ commit }, { collect, fieldName, fildValue }) {
      if (!fieldName || !fildValue || !collect) {
        commit('setError', 'Ошибка')
        return false
      }

      let answer = false

      try {
        const ref = collection(getFirestore(), collect)
        const q = query(ref, where(fieldName, '==', fildValue), limit(1))
        await getDocs(q)
          .then(async ans => {
            if (!ans.empty) {
              answer = 'exists'
            } else {
              answer = 'notexists'
            }
          })
      } catch (e) {
        commit('setError', e)
        return false
      }
      return answer
    },
    async saveCourseInfo({ commit }, courseInfo) {
      if (!courseInfo || !courseInfo.courseId) {
        commit('setError', 'Ошибка')
        return false
      }

      const courseInfoToStore = {}
      let courseId = courseInfo.courseId

      try {
        if (courseInfo.courseId === 'new') {
          courseId = doc(collection(getFirestore(), 'courses')).id
        }
        courseInfoToStore.courseId = courseId

        const batch = writeBatch(getFirestore())

        const courseData = {}
        if (courseInfo.publicName) {
          courseData.publicName = courseInfo.publicName
          courseInfoToStore.publicName = courseInfo.publicName
        }
        if (courseInfo.shortName) {
          courseData.shortName = courseInfo.shortName
          courseInfoToStore.shortName = courseInfo.shortName
        }
        if (courseInfo.landingPage) {
          courseData.landingPage = courseInfo.landingPage
          courseInfoToStore.landingPage = courseInfo.landingPage

          courseData.landingPageCreated = courseInfo.landingPage
          courseInfoToStore.landingPageCreated = courseInfo.landingPage
        }
        if (courseInfo.landingPageCreated !== undefined) {
          courseData.landingPageCreated = courseInfo.landingPageCreated
          courseInfoToStore.landingPageCreated = courseInfo.landingPageCreated
        }
        if (courseInfo.categories) {
          courseData.categories = courseInfo.categories
          courseInfoToStore.categories = courseInfo.categories
        }
        if (courseInfo.headers) {
          courseData.headers = courseInfo.headers
          courseInfoToStore.headers = courseInfo.headers
        }
        if (courseInfo.active !== undefined) {
          courseData.active = courseInfo.active
          courseInfoToStore.active = courseInfo.active
        }
        if (courseInfo.repeatDays !== undefined) {
          courseData.repeatDays = courseInfo.repeatDays
          courseInfoToStore.repeatDays = courseInfo.repeatDays
        }
        if (courseInfo.minGroupsRequired !== undefined) {
          courseData.minGroupsRequired = courseInfo.minGroupsRequired
          courseInfoToStore.minGroupsRequired = courseInfo.minGroupsRequired
        }
        if (courseInfo.googleDriveFolderId) {
          courseData.googleDriveFolderId = courseInfo.googleDriveFolderId
          courseInfoToStore.googleDriveFolderId = courseInfo.googleDriveFolderId
        }
        if (courseInfo.priceBase !== undefined && courseInfo.priceBase !== null) {
          courseData.priceBase = courseInfo.priceBase
          courseInfoToStore.priceBase = courseInfo.priceBase
        }
        if (courseInfo.bannerWhite) {
          courseData.bannerWhite = courseInfo.bannerWhite
          courseInfoToStore.bannerWhite = courseInfo.bannerWhite
        }
        if (courseInfo.logoSmall) {
          courseData.logoSmall = courseInfo.logoSmall
          courseInfoToStore.logoSmall = courseInfo.logoSmall
        }
        if (courseInfo.blocks) {
          courseData.blocks = courseInfo.blocks
          courseInfoToStore.blocks = courseInfo.blocks
        }
        if (courseInfo.description) {
          courseData.description = courseInfo.description
          courseInfoToStore.description = courseInfo.description
        }
        if (courseInfo.duration) {
          courseData.duration = courseInfo.duration
          courseInfoToStore.duration = courseInfo.duration
        }
        if (courseInfo.formats) {
          courseData.formats = courseInfo.formats
          courseInfoToStore.formats = courseInfo.formats
        }
        if (courseInfo.level) {
          courseData.level = courseInfo.level
          courseInfoToStore.level = courseInfo.level
        }
        if (courseInfo.requiredSkills) {
          courseData.requiredSkills = courseInfo.requiredSkills
          courseInfoToStore.requiredSkills = courseInfo.requiredSkills
        }
        if (courseInfo.softRestrictions) {
          courseData.softRestrictions = courseInfo.softRestrictions
          courseInfoToStore.softRestrictions = courseInfo.softRestrictions
        }

        if (Object.keys(courseData).length) {
          const courseRef = doc(getFirestore(), `courses/${courseId}`)
          if (courseInfo.courseId === 'new') {
            await getDoc(doc(getFirestore(), 'settings/stats'))
              .then(settings => {
                if (settings.exists()) {
                  courseData.courseNumber = +settings.data().lastCourseNumber + 1

                  const settinsRef = doc(getFirestore(), 'settings/stats')
                  batch.update(settinsRef, {
                    lastCourseNumber: increment(1)
                  })

                  courseInfoToStore.courseNumber = courseInfo.courseNumber
                }
              })
            batch.set(courseRef, courseData)
          } else {
            batch.update(courseRef, courseData)
          }
        }

        await batch.commit()

        if (Object.keys(courseInfoToStore).length) {
          await commit('setCourse', courseInfoToStore)
          commit('resetCourses')
        }

        return courseId
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    courses: s => s.courses,
    allCoursesArray: (s, getters) => {
      if (getters.coursesReset) {
        //
      }

      let arr = []

      const allCourses = Object.keys(s.courses)
      if (allCourses && allCourses.length) {
        arr = allCourses.slice(0)
      }

      if (arr.length > 1) {
        arr.sort((a, b) => {
          const courseA = s.courses[a]
          const courseB = s.courses[b]

          if (courseA.shortName === courseB.shortName) {
            if (courseA.courseId < courseB.courseId) { return -1 }
            if (courseA.courseId > courseB.courseId) { return 1 }
            return 0
          } else {
            if (courseA.shortName < courseB.shortName) { return -1 }
            if (courseA.shortName > courseB.shortName) { return 1 }
            return 0
          }
        })
      }

      return arr
    },
    coursesArray: (s, getters) => {
      if (getters.coursesReset) {
        //
      }

      let arr = []

      const allCourses = Object.keys(s.courses)
      if (allCourses && allCourses.length) {
        arr = allCourses.slice(0)
      }

      if (!s.showArchievedCourses) {
        arr = arr.filter(courseId => {
          const course = s.courses[courseId]
          return course.active
        })
      }

      if (s.coursesSearchStr && s.coursesSearchStr.length) {
        arr = arr.filter(courseId => {
          const course = s.courses[courseId]
          return ((course.publicName && course.publicName.toLowerCase().includes(s.coursesSearchStr.toLowerCase())) ||
            (course.shortName && course.shortName.toLowerCase().includes(s.coursesSearchStr.toLowerCase())) ||
            (course.landingPage && course.landingPage.toLowerCase().includes(s.coursesSearchStr.toLowerCase()))
          )
        })
      }

      if (arr.length > 1) {
        if (s.coursesSort.field === 'mainCategory' || !s.coursesSort.field) {
          arr.sort((a, b) => {
            const courseA = s.courses[a]
            const courseB = s.courses[b]

            if (getters.categories[courseA.categories[0]].fullName === getters.categories[courseB.categories[0]].fullName) {
              if (courseA.shortName < courseB.shortName) { return -1 }
              if (courseA.shortName > courseB.shortName) { return 1 }
              return 0
            } else {
              if (getters.categories[courseA.categories[0]].fullName < getters.categories[courseB.categories[0]].fullName) { return -1 }
              if (getters.categories[courseA.categories[0]].fullName > getters.categories[courseB.categories[0]].fullName) { return 1 }
              return 0
            }
          })
        } else if (s.coursesSort.field === 'shortName') {
          arr.sort((a, b) => {
            const courseA = s.courses[a]
            const courseB = s.courses[b]

            if (courseA.shortName === courseB.shortName) {
              if (courseA.courseId < courseB.courseId) { return -1 }
              if (courseA.courseId > courseB.courseId) { return 1 }
              return 0
            } else {
              if (courseA.shortName < courseB.shortName) { return -1 }
              if (courseA.shortName > courseB.shortName) { return 1 }
              return 0
            }
          })
        } else if (s.coursesSort.field === 'firstHeader') {
          arr.sort((a, b) => {
            const courseA = s.courses[a]
            const courseB = s.courses[b]

            if ((!courseA.headers || !courseA.headers.length) && (courseB.headers && courseB.headers.length)) { return -1 }
            if ((courseA.headers && courseA.headers.length) && (!courseB.headers || !courseB.headers.length)) { return 1 }
            if ((!courseA.headers || !courseA.headers.length) && (!courseB.headers || !courseB.headers.length)) { return 0 }
            if (getters.headers[courseA.headers[0]].fullName === getters.headers[courseB.headers[0]].fullName) {
              if (courseA.shortName < courseB.shortName) { return -1 }
              if (courseA.shortName > courseB.shortName) { return 1 }
              return 0
            } else {
              if (getters.headers[courseA.headers[0]].fullName < getters.headers[courseB.headers[0]].fullName) { return -1 }
              if (getters.headers[courseA.headers[0]].fullName > getters.headers[courseB.headers[0]].fullName) { return 1 }
              return 0
            }
          })
        } else if (s.coursesSort.field === 'groupsLength') {
          arr.sort((a, b) => {
            const courseAlength = getters.courseGroupsLength(a)
            const courseBlength = getters.courseGroupsLength(b)

            if (courseAlength === courseBlength) {
              const courseA = s.courses[a]
              const courseB = s.courses[b]

              if (courseA.shortName < courseB.shortName) { return -1 }
              if (courseA.shortName > courseB.shortName) { return 1 }
              return 0
            } else {
              if (courseAlength < courseBlength) { return -1 }
              if (courseAlength > courseBlength) { return 1 }
              return 0
            }
          })
        } else {
          arr.sort((a, b) => {
            const courseA = s.courses[a]
            const courseB = s.courses[b]

            if (!courseA[s.coursesSort.field]) { courseA[s.coursesSort.field] = '' }
            if (!courseB[s.coursesSort.field]) { courseB[s.coursesSort.field] = '' }

            if (courseA[s.coursesSort.field] === courseB[s.coursesSort.field]) {
              if (courseA.courseId < courseB.courseId) { return -1 }
              if (courseA.courseId > courseB.courseId) { return 1 }
              return 0
            } else {
              if (courseA[s.coursesSort.field] < courseB[s.coursesSort.field]) { return -1 }
              if (courseA[s.coursesSort.field] > courseB[s.coursesSort.field]) { return 1 }
              return 0
            }
          })
        }

        if (s.coursesSort.direction && s.coursesSort.direction === 'desc') {
          arr.reverse()
        }
      }

      return arr
    },
    courseGroupsLength: (s, getters) => courseId => {
      if (getters.coursesReset) {
        //
      }

      let lngth = 0

      if (courseId) {
        const allGroups = getters.groups
        let allGroupsIds = Object.keys(allGroups)

        allGroupsIds = allGroupsIds.filter(groupId => {
          return allGroups[groupId].courseId === courseId
        })

        allGroupsIds = allGroupsIds.filter(groupId => {
          return +allGroups[groupId].start > +new Date()
        })

        allGroupsIds = allGroupsIds.filter(groupId => {
          return allGroups[groupId].active
        })

        allGroupsIds = allGroupsIds.filter(groupId => {
          return allGroups[groupId].type === 'public'
        })

        lngth = allGroupsIds.length
      }

      return lngth
    },
    allActiveCoursesFetched: s => s.allActiveCoursesFetched,
    allArchievedCoursesFetched: s => s.allArchievedCoursesFetched,
    showArchievedCourses: s => s.showArchievedCourses,
    coursesSearchStr: s => s.coursesSearchStr,
    coursesSort: s => s.coursesSort,
    coursesReset: s => s.coursesReset
  }
}
