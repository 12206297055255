import store from '../store'

export default function createDateTimeStr({ date, hours = false, dayOfTheWeek = false, duration = 0, addDays = 0 }) {
  if (!date) { return '' }

  const addZero = (value) => {
    if (!value && value !== 0) { return '' }
    if (value < 10) { value = '0' + value }
    return value
  }

  const start = new Date(date)

  const dt = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes(), start.getUTCSeconds()))
  const utcDate = new Date(dt.toLocaleString('en-US', { timeZone: "UTC" }))
  const tzDate = new Date(dt.toLocaleString('en-US', { timeZone: store.getters.timeZone }))
  const offset = utcDate.getTime() - tzDate.getTime()
  const timezoneDifferece = offset / 1000 / 60

  const dateStart = new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate() + addDays, start.getUTCHours(), start.getUTCMinutes() - timezoneDifferece, start.getUTCSeconds())
  const fullDateFinish = new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate(), dateStart.getHours(), dateStart.getMinutes() + duration, dateStart.getSeconds())

  const days = ['вс ', 'пн ', 'вт ', 'ср ', 'чт ', 'пт ', 'сб ']

  const fullDate = `${dayOfTheWeek ? (days[dateStart.getDay()] + ' ') : ''}${addZero(dateStart.getDate())}.${addZero(dateStart.getMonth() + 1)}.${dateStart.getFullYear()}${hours ? (' >> ' + addZero(dateStart.getHours()) + ':' + addZero(dateStart.getMinutes()) + '-' + addZero(fullDateFinish.getHours()) + ':' + addZero(fullDateFinish.getMinutes())) : ''}`

  return fullDate
}
