<template>
  <div>
    <nav class="bg-emerald-500 shadow-sm fixed top-0 left-0 right-0 z-10">
      <div class="mx-2 px-2 py-2">
        <div class="relative flex items-center justify-between h-auto">
          <div class="flex items-center justify-start">
            <div class="block">
              <div class="flex space-x-2 sm:space-x-3">
                <a
                  href=""
                  @click.prevent="$emit('show-sidebar')"
                >
                  <svg
                    class="w-6 h-6 text-gray-900"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </a>

                <div class="mt-auto mb-auto">
                  <router-link
                    :to="{ name: 'Settings' }"
                    :title="createTimezoneTextInfo"
                  >
                    <img
                      src="@/assets/icons/schedule.svg"
                      class="w-5 h-5 opacity-50 cursor-pointer"
                    />
                  </router-link>
                </div>

                <div
                  v-if="createButton[page.name]"
                  :title="createButton[page.name].title"
                  class="cursor-pointer mt-auto mb-auto hover:opacity-75"
                  @click.prevent.stop="createButton[page.name].handler()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>

                <div
                  v-if="page.name === 'Groups'"
                  title="Пересоздавать полностью RealtimeDB"
                  class="cursor-pointer mt-auto mb-auto hover:opacity-75"
                >
                  <img
                    src="@/assets/icons/restart_alt_24dp_gray600.svg"
                    class="h-5 w-5"
                    @click.prevent.stop="resetAllInRealtimeDB()"
                  >
                </div>

                <div
                  v-if="page.name === 'Groups' && !loadedAllGroups"
                  title="Загрузить более ранние группы"
                  class="cursor-pointer mt-auto mb-auto"
                >
                  <a
                    href=""
                    class="text-sm w-min justify-center rounded-md border border-gray-300 shadow-sm px-2 py-0.5 bg-gray-200 font-medium text-gray-700 hover:bg-gray-300 focus:outline-none whitespace-nowrap"
                    @click.prevent.stop="fetchArchievedGroups()"
                  >
                    <span>+10</span>
                  </a>
                </div>

                <div
                  v-if="page.name === 'Groups'"
                  title="Настроить фильтры"
                  class="cursor-pointer mt-auto mb-auto hover:opacity-75"
                >
                  <img
                    v-if="groupsFiltered"
                    src="@/assets/icons/filter_alt_black_24dp_filled.svg"
                    class="h-5 w-5"
                    @click.prevent.stop="$store.commit('setShowGroupsFilters', true)"
                  >

                  <img
                    v-else
                    src="@/assets/icons/filter_alt_black_24dp_outlined.svg"
                    class="h-5 w-5"
                    @click.prevent.stop="$store.commit('setShowGroupsFilters', true)"
                  >
                </div>

                <div
                  title="Открыть календарь групп"
                  class="cursor-pointer mt-auto mb-auto hover:opacity-75"
                  @click="$store.commit('setShowSchedule', true)"
                >
                  <img
                    src="@/assets/icons/date_range_black_18dp.svg"
                    class="h-5 w-5"
                  >
                </div>

                <div
                  v-if="(page.name === 'Orders')"
                  title="Настройка загрузки заказов"
                  class="cursor-pointer mt-auto mb-auto"
                  :class="{'hover:opacity-75': ordersSettingsFiltered, 'opacity-50 hover:opacity-75': !ordersSettingsFiltered}"
                  @click.prevent.stop="showOrderSettingsFunc()"
                >
                  <img
                    src="@/assets/icons/settings_24dp.svg"
                    class="h-5 w-5"
                  >
                </div>

                <div
                  v-if="page.name === 'Courses'"
                  title="Показать архивные курсы"
                  class="cursor-pointer mt-auto mb-auto hover:opacity-75"
                  :class="{'text-gray-700': showArchievedCourses, 'text-gray-500': !showArchievedCourses}"
                  @click.prevent.stop="showArchievedCoursesFunc()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5  "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                    />
                  </svg>
                </div>

                <div
                  v-if="page.name === 'Courses' || page.name === 'Tasks'  || page.name === 'Orders'"
                  class="flex flex-row items-center mr-5 py-0.5 px-2 shadow-lg rounded h-6"
                  :class="{'bg-gray-200 border-gray-200': !searchStr.length, 'bg-gray-400 border-gray-400': searchStr.length}"
                >
                  <img
                    src="@/assets/icons/search_18dp_gray600.svg"
                    class="h-4 w-4 opacity-75 hover:opacity-50 cursor-pointer"
                    @click.prevent.stop="focusSearchField()"
                  >

                  <input
                    ref="searchStr"
                    v-model.trim="searchStr"
                    type="text"
                    autocomplete="off"
                    placeholder="Искать"
                    class="sm:text-sm text-left focus:outline-none px-2 w-full"
                    :class="{'bg-gray-200': !searchStr.length, 'bg-gray-400': searchStr.length}"
                  >

                  <img
                    v-if="searchStr && searchStr.length"
                    src="@/assets/icons/close_18dp_gray600.svg"
                    class="h-4 w-4 opacity-75 hover:opacity-50 cursor-pointer"
                    @click.prevent.stop="searchStr = '', focusSearchField()"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import useNotifications from '@/composables/useNotifications'

export default {
  name: 'NavbarNav',
  setup () {
    const { toastify } = useNotifications()
    return { toastify }
  },
  computed: {
    page () {
      return this.$route
    },
    loadedAllGroups () {
      return this.$store.getters.loadedAllGroups
    },
    groupsFiltered () {
      return this.$store.getters.groupsFiltered
    },
    showArchievedCourses () {
      return this.$store.getters.showArchievedCourses
    },
    ordersSettingsFiltered () {
      return this.$store.getters.ordersSettingsFiltered
    },
    createTimezoneTextInfo() {
      return this.$store.getters.createTimezoneTextInfo
    },
    searchStr: {
      get () {
        if (this.page.name === 'Courses') {
          return this.$store.getters.coursesSearchStr
        } else if (this.page.name === 'Tasks') {
          return this.$store.getters.tasksSearchStr
        } else if (this.page.name === 'Orders') {
          return this.$store.getters.ordersSearchStr
        }
        return null
      },
      set (value) {
        if (this.page.name === 'Courses') {
          this.$store.commit('setCoursesSearchStr', value)
        } else if (this.page.name === 'Tasks') {
          this.$store.commit('setTasksSearchStr', value)
        } else if (this.page.name === 'Orders') {
          this.$store.commit('setOrdersSearchStr', value)
        }
      }
    },
    createButton () {
      return {
        'Groups' : { title: 'Cоздать группу', handler: this.createGroup },
        'Courses' : { title: 'Cоздать курс', handler: this.createCourse },
        'Tasks' : { title: 'Cоздать задачу', handler: this.createTask },
        'Orders' : { title: 'Добавить заказ', handler: this.createOrder },
        'Headers' : { title: 'Cоздать хедер', handler: this.createHeader },
        'Categories' : { title: 'Cоздать категорию', handler: this.createCategory }
      }
    }
  },
  methods: {
    createGroup () {
      this.$router.push({ name: 'GroupEdit', params: { id: 'new' } }).catch(() => {})
    },
    createCourse () {
      this.$router.push({ name: 'CourseEdit', params: { courseId: 'new' } }).catch(() => {})
    },
    createTask () {
      this.$store.commit('setShowCreateTask', true)
    },
    createOrder () {
      this.$store.commit('setShowEditOrder', true)
    },
    createHeader () {
      this.$router.push({ name: 'Header', params: { headerId: 'new' } }).catch(() => {})
    },
    createCategory () {
      this.$router.push({ name: 'Category', params: { categoryId: 'new' } }).catch(() => {})
    },
    async resetAllInRealtimeDB () {
      const toastId = this.toastify.warning('Работаю...', { timeout: null })
      const resetAllInRealtimeDBFunc = httpsCallable(getFunctions(getApp()), 'resetAllInRealtimeDB')
      const answer = await resetAllInRealtimeDBFunc()

      if (answer && answer.data) {
        this.toastify.replace(toastId, 'Готово', 'success')
      } else {
        this.toastify.replace(toastId, 'Не удалось', 'error')
      }
    },
    async fetchArchievedGroups () {
      await this.$store.commit('setShowFinishedGroups', true)

      if (this.loadedAllGroups) { return }

      const toastId = this.toastify.warning('Загружаю...', { timeout: null })
      await this.$store.dispatch('fetchArchievedGroups')
      this.toastify.remove(toastId)
    },
    async showArchievedCoursesFunc () {
      if (!this.showArchievedCourses) {
        const toastId = this.toastify.warning('Загружаю...', { timeout: null })

        await this.$store.dispatch('fetchAllCoursesList', { active: false })
        this.toastify.replace(toastId, 'Загружено', 'success')
      }

      this.$store.commit('setShowArchievedCourses', !this.showArchievedCourses)
    },
    async showOrderSettingsFunc () {
      this.$store.commit('setShowOrderSettings', true)
      this.$store.commit('setOrderSearchStrOrderId', null)
      this.$store.commit('setOrdersSearchStr', '')
    },
    focusSearchField () {
      this.$refs.searchStr.focus()
    }
  }
}
</script>
