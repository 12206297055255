import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'

export default {
  actions: {
    async login ({ dispatch, commit }, { email, password }) {
      await commit('clearInfo')

      try {
        const auth = getAuth()

        await signInWithEmailAndPassword(auth, email, password)

        const isAdmin = await dispatch('isAdmin')
        if (!isAdmin) {
          await commit('setError', 'Доступ запрещен')
          await dispatch('logout')
          return false
        }
      } catch (e) {
        commit('setError', e)
        throw e
      }

      return true
    },
    async logout ({ commit }) {
      const auth = getAuth()
      await signOut(auth)
      await commit('clearInfo')
    },
    async isAdmin () {
      const user = getAuth().currentUser
      if (!user) { return false }
      const idTokenResult = await user.getIdTokenResult()
      if (!idTokenResult || !idTokenResult.claims || !idTokenResult.claims.admin) { return false }
      return true
    },
    getUid () {
      const user = getAuth().currentUser
      return user ? user.uid : null
    }
  }
}
