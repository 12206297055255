import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import { getApp, initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, connectAuthEmulator } from 'firebase/auth'
import { connectDatabaseEmulator, getDatabase } from 'firebase/database'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"

import dateFilter from '@/filters/date.filter'
import datetimeFilter from '@/filters/datetime.str'
import hoursToMins from '@/filters/hoursToMins'
import currencyFilter from '@/filters/currency.filter'

import 'tailwindcss/tailwind.css'

import VIntersection from '@/directives/VIntersection'

import Loader from '@/components/app/loaders/Loader'

const firebaseConfig = {
  apiKey: 'AIzaSyA-1XDlq26Ix57nhaxBohHdplHRwcN0atc',
  authDomain: 'sreda31-courses.firebaseapp.com',
  databaseURL: 'https://sreda31-courses-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'sreda31-courses',
  storageBucket: 'sreda31-courses.appspot.com',
  messagingSenderId: '513275254960',
  appId: '1:513275254960:web:7df4ce736c8d5cf5a70375'
}

const firebaseApp = initializeApp(firebaseConfig)

let app

const auth = getAuth(firebaseApp)

if (location.hostname === 'localhost1') {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectDatabaseEmulator(getDatabase(), "localhost", 9000);
  connectFirestoreEmulator(getFirestore(), 'localhost', 8081);

  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "localhost", 5002);
}

onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App)
    app.use(router)
    app.use(store)

    app.directive(VIntersection.name, VIntersection)

    app.component('LoaderComponent', Loader)

    app.config.globalProperties.$filters = {
      dateFilter, datetimeFilter, hoursToMins, currencyFilter
    }

    app.mount('#uapp')
  }
})
