<template>
  <svg
    class="hover:opacity-50"
    :class="{'w-5 h-5': size5, 'text-gray-700': textGray700}"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size5: {
      type: Boolean,
      default: true
    },
    textGray700: {
      type: Boolean,
      default: true
    }
  }
}
</script>
