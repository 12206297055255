import { getFirestore, getDoc, getDocs, doc, collection, query, where, writeBatch, increment, onSnapshot, orderBy, limit, startAfter } from 'firebase/firestore'

export default {
  state: {
    students: {},
    totalNumberOfStudents: null,
    totalNumberOfBlacklist: null,
    totalNumberOfSubscribedEmails: null,
    studentsReset: 0,
    studentsUnsubscribe: null,
    statsUnsubscribe: null
  },
  mutations: {
    setStudent(state, studentData) {
      if (!studentData || !studentData.studentId) {
        return
      }

      state.students[studentData.studentId] = studentData
    },
    updateStudent(state, { studentId, studentData }) {
      if (studentId && state.students[studentId]) {
        for (const field of Object.keys(studentData)) {
          state.students[studentId][field] = studentData[field]
        }
      }
    },
    setStudentsUnsubscribe(state, value) {
      state.studentsUnsubscribe = value
    },
    setStatsUnsubscribe(state, value) {
      state.statsUnsubscribe = value
    },
    setTotalNumberOfStudents(state, value) {
      state.totalNumberOfStudents = value
    },
    setTotalNumberOfBlacklist(state, value) {
      state.totalNumberOfBlacklist = value
    },
    setTotalNumberOfSubscribedEmails(state, value) {
      state.totalNumberOfSubscribedEmails = value
    },
    resetStudents(state) {
      state.studentsReset = Date.now()
    },
    clearInfo(state) {
      state.students = {}
      state.totalNumberOfStudents = null
      state.totalNumberOfBlacklist = null
      state.totalNumberOfSubscribedEmails = null
      state.studentsUnsubscribe = null
      state.statsUnsubscribe = null
    }
  },
  actions: {
    async fetchStudentById({ commit, dispatch }, studentId) {
      if (!studentId) {
        commit('setError', 'Ошибка')
        return
      }

      try {
        await getDoc(doc(getFirestore(), `students/${studentId}`))
          .then(async student => {
            if (student.exists()) {
              const studentData = await dispatch('studentFetched', { studentData: student.data(), studentId: studentId })
              await commit('setStudent', studentData)
            }
          })

        await commit('resetStudents')
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchStudentByEmail({ commit, dispatch }, email) {
      if (!email) {
        commit('setError', 'Ошибка')
        return
      }

      const studentsIds = []

      try {
        const ref = collection(getFirestore(), 'students')
        const q = query(ref, where('email', '==', email))
        const students = await getDocs(q)

        if (!students.empty) {
          students.forEach(async student => {
            if (student.exists()) {
              const studentData = await dispatch('studentFetched', { studentData: student.data(), studentId: student.id })

              if (!studentsIds.includes(student.id)) { studentsIds.push(student.id) }
              await commit('setStudent', studentData)
            }
          })
        }

        await commit('resetStudents')
        return studentsIds
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchStudentByLastName({ commit, dispatch }, lastname) {
      if (!lastname) {
        commit('setError', 'Ошибка')
        return
      }

      const studentsIds = []

      try {
        const ref = collection(getFirestore(), 'students')
        const q = query(ref, where('lastname', '==', lastname))
        const students = await getDocs(q)

        if (!students.empty) {
          students.forEach(async student => {
            if (student.exists()) {
              const studentData = await dispatch('studentFetched', { studentData: student.data(), studentId: student.id })

              if (!studentsIds.includes(student.id)) { studentsIds.push(student.id) }
              await commit('setStudent', studentData)
            }
          })
        }

        await commit('resetStudents')
        return studentsIds
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchStudentByPhoneNumber({ commit, dispatch }, phoneNumber) {
      if (!phoneNumber) {
        commit('setError', 'Ошибка')
        return
      }

      const studentsIds = []

      try {
        const ref = collection(getFirestore(), 'students')
        const q = query(ref, where('phone', '==', +phoneNumber))
        const students = await getDocs(q)

        if (!students.empty) {
          students.forEach(async student => {
            if (student.exists()) {
              const studentData = await dispatch('studentFetched', { studentData: student.data(), studentId: student.id })

              if (!studentsIds.includes(student.id)) { studentsIds.push(student.id) }
              await commit('setStudent', studentData)
            }
          })
        }

        await commit('resetStudents')
        return studentsIds
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchEmailSubscriptionsByStudentId({ commit }, { studentId, lastFetchedEmail }) {
      if (!studentId) {
        commit('setError', 'Ошибка')
        return
      }

      const maxLength = 10

      try {
        const emailsRef = collection(getFirestore(), 'emails')
        const queries = [orderBy('timestamp', 'desc'), limit(maxLength), where('studentId', '==', studentId)]

        if (lastFetchedEmail) { queries.push(startAfter(lastFetchedEmail)) }

        const emailsQ = query(emailsRef, ...queries)

        const fetchedEmails = []

        const emails = await getDocs(emailsQ)
        if (!emails.empty) {
          for (const email of emails.docs) {
            if (email.exists()) {
              const emailData = email.data()
              if (emailData.timestamp && emailData.timestamp.toDate) { emailData.timestamp = emailData.timestamp.toDate() }
              emailData.id = email.id
              fetchedEmails.push(emailData)
            }
          }
        }

        const data = {}

        if (fetchedEmails.length) { data.emails = fetchedEmails }
        if (!emails.empty) { data.lastFetchedEmail = emails.docs[emails.docs.length - 1] }
        if (emails.empty || emails.size < maxLength) { data.allEmailsFetched = true }

        return data
      } catch (e) {
        commit('setError', e)
      }
    },
    async subscribeToStudents({ dispatch, commit }) {
      try {
        await dispatch('unsubscribeStudents')

        const ref = collection(getFirestore(), 'students')
        const q = query(ref, where('updated', '>=', new Date()), orderBy('updated', 'asc'))

        const unsubscribe = onSnapshot(q, async querySnapshot => {
          querySnapshot.docChanges().forEach(async change => {
            const studentData = await dispatch('studentFetched', { studentData: change.doc.data(), studentId: change.doc.id })
            await commit('setStudent', studentData)
          })

          await commit('resetStudents')
        }, (error) => {
          commit('setError', error)
        })

        await commit('setStudentsUnsubscribe', unsubscribe)
      } catch (e) {
        commit('setError', e)
      }
    },
    async unsubscribeStudents({ commit, getters }) {
      try {
        const studentsUnsubscribe = getters.studentsUnsubscribe
        if (studentsUnsubscribe) {
          await studentsUnsubscribe()
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    studentFetched(context, { studentData, studentId }) {
      if (!studentData || !studentId) { return }

      if (studentData.lastAgree && studentData.lastAgree.toDate) { studentData.lastAgree = studentData.lastAgree.toDate() }
      if (studentData.timestamp && studentData.timestamp.toDate) { studentData.timestamp = studentData.timestamp.toDate() }
      if (studentData.updated && studentData.updated.toDate) { studentData.updated = studentData.updated.toDate() }

      if (studentData.oldLastNames) {
        for (const value of studentData.oldLastNames) {
          if (value.timestamp && value.timestamp.toDate) { value.timestamp = value.timestamp.toDate() }
        }
      }

      if (studentData.oldNames) {
        for (const value of studentData.oldNames) {
          if (value.timestamp && value.timestamp.toDate) { value.timestamp = value.timestamp.toDate() }
        }
      }

      if (studentData.oldPhones) {
        for (const value of studentData.oldPhones) {
          if (value.timestamp && value.timestamp.toDate) { value.timestamp = value.timestamp.toDate() }
        }
      }

      studentData.studentId = studentId

      return studentData
    },
    async subscribeToStats({ dispatch, commit }) {
      try {
        await dispatch('unsubscribeStats')

        const ref = doc(getFirestore(), 'settings/stats')
        const unsubscribe = onSnapshot(ref, async stats => {
          if (stats.exists()) {
            const data = stats.data()

            commit('setTotalNumberOfStudents', data.totalNumberOfStudents)
            commit('setTotalNumberOfBlacklist', data.totalNumberOfBlacklist)
            commit('setTotalNumberOfSubscribedEmails', data.totalNumberOfSubscribedEmails)
          }
        }, (error) => {
          commit('setError', error)
        })

        await commit('setStatsUnsubscribe', unsubscribe)
      } catch (e) {
        commit('setError', e)
      }
    },
    async unsubscribeStats({ commit, getters }) {
      try {
        const statsUnsubscribe = getters.statsUnsubscribe
        if (statsUnsubscribe) {
          await statsUnsubscribe()
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async createStudent({ commit }, studentData) {
      if (!studentData || !studentData.email || !studentData.timestamp) {
        commit('setError', 'Ошибка создания студента')
        return
      }

      const student = {
        timestamp: studentData.timestamp,
        lastAgree: studentData.timestamp,
        email: studentData.email,
        name: studentData.name,
        lastname: studentData.lastname,
        phone: studentData.phone,
        email_subscribed: true
      }

      if (studentData.lastAgree) {
        student.lastAgree = studentData.lastAgree
      }

      try {
        const batch = writeBatch(getFirestore())

        const studentId = doc(collection(getFirestore(), 'students')).id
        const studentRef = doc(getFirestore(), `students/${studentId}`)
        batch.set(studentRef, student)

        await batch.commit()

        student.studentId = studentId
        await commit('setStudent', student)

        return studentId
      } catch (e) {
        commit('setError', e)
      }
    },
    async editStudent({ commit, getters }, { studentId, studentData, comment }) {
      if (!studentData || !studentId || !Object.keys(studentData).length) {
        commit('setError', 'Ошибка')
        return
      }

      const timestamp = new Date()

      try {
        const data = {}

        for (const field of Object.keys(studentData)) {
          if (studentData[field]) {
            data[field] = studentData[field]
          } else if (studentData[field] === false) {
            data[field] = false
          } else {
            data[field] = null
          }
        }

        const batch = writeBatch(getFirestore())

        const studentRef = doc(getFirestore(), `students/${studentId}`)
        batch.update(studentRef, data)

        if (studentData.email_subscribed === false && comment) {
          const emailId = doc(collection(getFirestore(), 'emails')).id
          const emailRef = doc(getFirestore(), `emails/${emailId}`)
          batch.set(emailRef, {
            timestamp: timestamp,
            service_type: 'subscription',
            action: 'unsubscribed-manually',
            comment: comment,
            studentId: studentId,
            email: (getters.students[studentId] && getters.students[studentId].email) ? getters.students[studentId].email : null
          })
        }

        let incr = 0
        if (studentData.blacklistReason !== undefined) {
          const student = getters.students[studentId]

          if (studentData.blacklistReason && !student.blacklistReason) {
            incr = 1
          } else if (!studentData.blacklistReason && student.blacklistReason) {
            incr = -1
          }
        }

        if (studentData.email_subscribed === false) { incr = -1 }

        if (incr) {
          const statsRef = doc(getFirestore(), 'settings/stats')
          batch.update(statsRef, {
            totalNumberOfBlacklist: increment(incr)
          })
        }

        await batch.commit()

        await commit('updateStudent', { studentId: studentId, studentData: data })
        await commit('resetStudents')

        return true
      } catch (e) {
        commit('setError', e)
      }
    }
  },
  getters: {
    students: s => s.students,
    getStudentByEmail: s => email => {
      if (!email) { return null }

      let studentsIds = Object.keys(s.students)

      if (studentsIds.length) {
        studentsIds = studentsIds.filter(studentId => {
          return s.students[studentId].email === email
        })
      }

      if (!studentsIds.length) { return null }
      return studentsIds[0]
    },
    totalNumberOfStudents: s => s.totalNumberOfStudents,
    totalNumberOfBlacklist: s => s.totalNumberOfBlacklist,
    totalNumberOfSubscribedEmails: s => s.totalNumberOfSubscribedEmails,
    studentsReset: s => s.studentsReset,
    studentsUnsubscribe: s => s.studentsUnsubscribe,
    statsUnsubscribe: s => s.statsUnsubscribe
  }
}
