import { getFirestore, getDocs, doc, collection, query, where, orderBy, updateDoc, onSnapshot } from 'firebase/firestore'
import useNotifications from '@/composables/useNotifications'
const { toastify } = useNotifications()

export default {
  state: {
    feedback: {},
    feedbackReset: 0,
    feedbackUnsubscribe: null
  },
  mutations: {
    setFeedback(state, feedbackData) {
      if (!feedbackData || !feedbackData.feedbackId) {
        return
      }

      state.feedback[feedbackData.feedbackId] = feedbackData
    },
    setFeedbackUnsubscribe(state, value) {
      state.feedbackUnsubscribe = value
    },
    resetFeedback(state) {
      state.feedbackReset = Date.now()
    },
    clearInfo(state) {
      state.feedback = {}
      state.feedbackUnsubscribe = null
    }
  },
  actions: {
    async subscribeToFeedback({ dispatch, commit }) {
      try {
        await dispatch('unsubscribeFeedback')

        const ref = collection(getFirestore(), 'feedback')
        const q = query(ref, where('active', '==', true), orderBy('timestamp', 'asc'))

        const unsubscribe = onSnapshot(q, async querySnapshot => {
          let haveFeedback = false

          querySnapshot.docChanges().forEach(async change => {
            if (change.type === 'added') {
              haveFeedback = true
              const feedbackData = change.doc.data()
              await dispatch('feedbackReceived', { feedbackId: change.doc.id, feedbackData: feedbackData })
            }
          })

          if (haveFeedback) {
            toastify.warning('Есть новые сообщения обратной связи', { timeout: null })
          }
        }, (error) => {
          commit('setError', error)
        })

        await commit('setFeedbackUnsubscribe', unsubscribe)
      } catch (e) {
        commit('setError', e)
      }
    },
    async unsubscribeFeedback({ commit, getters }) {
      try {
        const feedbackUnsubscribe = getters.feedbackUnsubscribe
        if (feedbackUnsubscribe) {
          await feedbackUnsubscribe()
        }
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchFeedbackByOrderStudentId({ commit, dispatch }, studentId) {
      if (!studentId) {
        commit('setError', 'Не указан Stident ID')
        return false
      }

      try {
        const ref = collection(getFirestore(), 'feedback')
        const queries = [where('studentId', '==', studentId), orderBy('timestamp', 'desc')]
        const q = query(ref, ...queries)
        const feedbacks = await getDocs(q)

        if (!feedbacks.empty) {
          feedbacks.forEach(async feedback => {
            if (feedback.exists()) {
              const feedbackData = feedback.data()
              await dispatch('feedbackReceived', { feedbackId: feedback.id, feedbackData: feedbackData })
            }
          })
        }

        return true
      } catch (e) {
        commit('setError', e)
      }
    },
    async feedbackReceived({ commit, dispatch, getters }, { feedbackId, feedbackData }) {
      if (!feedbackId) {
        commit('setError', 'Не указан feedbackId')
        return false
      }

      if (!feedbackData) {
        commit('setError', 'Не задана feedbackData')
        return false
      }

      try {
        if (feedbackData.timestamp && feedbackData.timestamp.toDate) {
          feedbackData.timestamp = feedbackData.timestamp.toDate()
        }

        feedbackData.feedbackId = feedbackId

        if (feedbackData.studentId && !getters.students[feedbackData.studentId]) {
          await dispatch('fetchStudentById', feedbackData.studentId)
        }

        await commit('setFeedback', feedbackData)
        await commit('resetFeedback')
      } catch (e) {
        commit('setError', e)
      }
    },
    async markFeedbackDone({ dispatch, commit, getters }, feedbackId) {
      if (!feedbackId) {
        commit('setError', 'Не указан ID')
        return false
      }

      const uid = await dispatch('getUid')

      const timestamp = new Date()

      const feedbackData = {
        active: false,
        markedDoneUserId: uid,
        markedDoneTimestamp: timestamp
      }

      const oldData = getters.feedback[feedbackId]
      oldData.active = false
      oldData.markedDoneUserId = uid
      oldData.markedDoneTimestamp = timestamp

      try {
        const ref = doc(getFirestore(), `feedback/${feedbackId}`)

        await updateDoc(ref, feedbackData)
          .then(async () => {
            await commit('setFeedback', oldData)
            await commit('resetFeedback')
          })
      } catch (e) {
        commit('setError', e)
        return false
      }
      return true
    }
  },
  getters: {
    feedback: s => s.feedback,
    feedbackReset: s => s.feedbackReset,
    feedbackArr: (s, getters) => active => {
      if (getters.feedbackReset) {
        //
      }

      let ids = Object.keys(s.feedback)

      ids = ids.filter(id => {
        return s.feedback[id].active === active
      })

      if (ids.length && ids.length > 1) {
        ids.sort((a, b) => {
          if (+s.feedback[a].timestamp < +s.feedback[b].timestamp) { return -1 }
          if (+s.feedback[a].timestamp > +s.feedback[b].timestamp) { return 1 }
          return 0
        })
      }

      return ids
    },
    feedbackArrByStudentId: (s, getters) => studentId => {
      if (getters.feedbackReset) {
        //
      }

      if (!studentId) { return [] }

      let ids = Object.keys(s.feedback)

      ids = ids.filter(feedbackId => {
        return s.feedback[feedbackId].studentId === studentId
      })

      if (ids.length && ids.length > 1) {
        ids.sort((a, b) => {
          if (+s.feedback[a].timestamp < +s.feedback[b].timestamp) { return -1 }
          if (+s.feedback[a].timestamp > +s.feedback[b].timestamp) { return 1 }
          return 0
        })
      }

      return ids
    },
    feedbackUnsubscribe: s => s.feedbackUnsubscribe
  }
}
