import { getFirestore, getDoc, getDocs, doc, collection, query, writeBatch } from 'firebase/firestore'

export default {
  state: {
    categories: {},
    allCategoriesFetched: false,
    categoriesSort: {
      field: 'fullName',
      direction: 'asc'
    }
  },
  mutations: {
    async setCategory(state, categoryInfo) {
      if (!categoryInfo || !categoryInfo.categoryId) {
        return
      }

      if (!state.categories[categoryInfo.categoryId]) {
        state.categories[categoryInfo.categoryId] = {}
      }

      for (const i in Object.keys(categoryInfo)) {
        state.categories[categoryInfo.categoryId][Object.keys(categoryInfo)[i]] = categoryInfo[Object.keys(categoryInfo)[i]]
      }
    },
    async setAllCategoriesFetched(state) {
      state.allCategoriesFetched = true
    },
    setCategoriesSortDirection(state, value) {
      state.categoriesSort.direction = value
    },
    setCategoriesSortField(state, value) {
      state.categoriesSort.field = value
    },
    clearCategoriesSort(state) {
      state.categoriesSort = {
        field: 'fullName',
        direction: 'asc'
      }
    },
    clearInfo(state) {
      state.categories = {}
      state.allCategoriesFetched = false
      state.categoriesSort = {
        field: 'fullName',
        direction: 'asc'
      }
    }
  },
  actions: {
    async fetchAllCategories({ commit }) {
      try {
        const ref = collection(getFirestore(), 'categories')
        const q = query(ref)
        await getDocs(q)
          .then(async categories => {
            if (!categories.empty) {
              categories.forEach(async category => {
                if (category.exists()) {
                  let categoryData = {}
                  categoryData = category.data()
                  categoryData.categoryId = category.id
                  await commit('setCategory', categoryData)
                }
              })
              await commit('setAllCategoriesFetched')
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async fetchCategory({ commit }, categoryId) {
      if (!categoryId) {
        return
      }

      try {
        await getDoc(doc(getFirestore(), `categories/${categoryId}`))
          .then(async category => {
            if (category.exists()) {
              let categoryData = {}
              categoryData = category.data()
              categoryData.categoryId = category.id
              await commit('setCategory', categoryData)
            }
          })
      } catch (e) {
        commit('setError', e)
      }
    },
    async saveCategoryInfo({ commit }, categoryInfo) {
      if (!categoryInfo || !categoryInfo.categoryId) {
        commit('setError', 'Ошибка')
        return false
      }

      let categoryId = categoryInfo.categoryId

      try {
        if (categoryInfo.categoryId === 'new') {
          categoryId = doc(collection(getFirestore(), 'categories')).id
        }

        const batch = writeBatch(getFirestore())

        const categoryData = {}
        if (categoryInfo.fullName) {
          categoryData.fullName = categoryInfo.fullName
        }
        if (categoryInfo.name) {
          categoryData.name = categoryInfo.name
        }
        if (categoryInfo.categoryPageCreated !== undefined) {
          categoryData.categoryPageCreated = categoryInfo.categoryPageCreated
        }
        if (categoryInfo.logoSmallUrl) {
          categoryData.logoSmallUrl = categoryInfo.logoSmallUrl
        }

        if (Object.keys(categoryData).length) {
          const categoryRef = doc(getFirestore(), `categories/${categoryId}`)
          if (categoryInfo.categoryId === 'new') {
            batch.set(categoryRef, categoryData)
          } else {
            batch.update(categoryRef, categoryData)
          }

          await batch.commit().then(async () => {
            categoryData.categoryId = categoryId
            await commit('setCategory', categoryData)
          })

          return categoryId
        }
      } catch (e) {
        commit('setError', e)
        return false
      }
    }
  },
  getters: {
    categories: s => s.categories,
    categoriesArray: s => {
      let arr = []

      const allCategories = Object.keys(s.categories)
      if (allCategories && allCategories.length) {
        arr = allCategories.slice(0)
      }

      if (arr.length > 1) {
        if (s.categoriesSort.field === 'fullName' || !s.categoriesSort.field) {
          arr.sort((a, b) => {
            const categoryA = s.categories[a]
            const categoryb = s.categories[b]

            if (categoryA.shortName < categoryb.fullName) { return -1 }
            if (categoryA.shortName > categoryb.fullName) { return 1 }
            return 0
          })
        } else if (s.categoriesSort.field === 'url') {
          arr.sort((a, b) => {
            const categoryA = s.categories[a]
            const categoryb = s.categories[b]

            if (categoryA.shortName < categoryb.name) { return -1 }
            if (categoryA.shortName > categoryb.name) { return 1 }
            return 0
          })
        }

        if (s.categoriesSort.direction && s.categoriesSort.direction === 'desc') {
          arr.reverse()
        }
      }

      return arr
    },
    allCategoriesFetched: s => s.allCategoriesFetched,
    categoriesSort: s => s.categoriesSort
  }
}
