export default {
  level: {
    text: 'Уровень курса',
    url: 'https://sreda31.com/wp-content/uploads/2017/12/icon_level.svg'
  },
  requiredSkills: {
    text: 'Знания, необходимые для успешного прохождения курса',
    url: 'https://sreda31.com/wp-content/uploads/2017/10/icon_necessary-knowledge.svg'
  },
  blocks: {
    text: 'Программа курса',
    url: 'https://sreda31.com/wp-content/uploads/2017/10/icon_programma.svg'
  },
  duration: {
    text: 'Продолжительность курса',
    url: 'https://sreda31.com/wp-content/uploads/2017/03/icon_duration.svg'
  },
  price: {
    text: 'Стоимость участия',
    url: 'https://sreda31.com/wp-content/uploads/2017/11/icon_price.svg'
  },
  sertificate: {
    text: 'Документ об окончании курса',
    url: 'https://sreda31.com/wp-content/uploads/2017/10/icon_sertificate.svg'
  },
  schedule: {
    text: 'Расписание',
    url: 'https://sreda31.com/wp-content/uploads/2018/10/schedule.svg'
  },
  otherCourses: {
    text: 'Вас также могут заинтересовать другие наши курсы',
    url: 'https://sreda31.com/wp-content/uploads/2018/10/other_cources.svg'
  }
}
