<template>
  <div class="min-h-screen min-w-min h-auto w-auto inset-0 bg-indigo-200 px-5 pt-12 pb-5">
    <LoaderComponent v-if="loading" />

    <div v-else>
      <Navbar @show-sidebar="showSidebar = !showSidebar" />

      <Sidebar
        :show="showSidebar"
        @show-sidebar="showSidebar = !showSidebar"
      />

      <GroupsSchedule v-if="showSchedule" />

      <main>
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import Navbar from '@/components/app/Navbar'
import Sidebar from '@/components/app/Sidebar'

export default {
  name: 'MainLayout',
  components: {
    Navbar, Sidebar,
    GroupsSchedule: defineAsyncComponent(() => import('@/components/groups/GroupsSchedule.vue')),
  },
  data: () => ({
    loading: true,
    showSidebar: false
  }),
  computed: {
    ...mapGetters([
      'showSchedule'
    ])
  },
  watch: {
    $route () {
      this.showSidebar = false
    }
  },
  async created () {
    this.loading = true

    if (!this.$store.getters.feedbackUnsubscribe) {
      this.$store.dispatch('subscribeToFeedback')
    }

    if (!this.$store.getters.timeZone) {
      const timezoneAnswer = await this.$store.dispatch('fetchTimezone')
      if (!timezoneAnswer) { this.$store.commit('setTimezone', Intl.DateTimeFormat().resolvedOptions().timeZone)}
    }

    if (!this.$store.getters.ordersUnsubscribe) {
      this.$store.dispatch('subscribeToOrders')
    }

    if (!this.$store.getters.studentsUnsubscribe) {
      this.$store.dispatch('subscribeToStudents')
    }

    if (!this.$store.getters.statsUnsubscribe) {
      this.$store.dispatch('subscribeToStats')
    }

    this.loading = false
  },
  mounted () {
    document.addEventListener('keydown', this.clickedButton)
  },
  async beforeUnmount () {
    document.removeEventListener('keydown', this.clickedButton)
    await this.$store.dispatch('unsubscribeFeedback')
    await this.$store.dispatch('unsubscribeOrders')
    await this.$store.dispatch('unsubscribeStudents')
    await this.$store.dispatch('unsubscribeStats')
  },
  methods: {
    clickedButton (event) {
      if (event.code === 'Escape') {
        this.showSidebar = false
      }
    }
  }
}
</script>
