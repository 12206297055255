<template>
  <div>
    <nav
      class="fixed py-4 px-2 bg-indigo-300 z-30 inset-0 w-60 overflow-y-auto transform transition-transform duration-300 ease-in"
      :class="{ '-translate-x-60': !show, 'translate-x-0': show }"
    >
      <div class="mx-2 min-h-full flex flex-col place-content-between">
        <div class="">
          <router-link
            active-class="text-gray-500"
            :to="{ name: 'Tasks' }"
            :exact="true"
          >
            <p @click="$emit('show-sidebar')">
              Задачи
            </p>
          </router-link>

          <div class="relative text-gray-500 justify-center text-center text-sm border-t border-gray-400 -mb-1.5 mt-2.5">
            <div class="inline-block transform -translate-y-2.5 text-center bg-indigo-300 w-max px-1.5">
              Расписание
            </div>
          </div>

          <div class="flex flex-row mb-1">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Groups' }"
            >
              <span @click="$emit('show-sidebar')">
                Группы
              </span>
            </router-link>

            <div
              title="Cоздать группу"
              class="ml-1 mt-1 cursor-pointer"
              @click.prevent.stop="createGroup()"
            >
              <PlusEmptyRoundedButton />
            </div>
          </div>

          <div class="flex flex-row mb-1">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Courses' }"
            >
              <span @click="$emit('show-sidebar')">
                Курсы
              </span>
            </router-link>

            <div
              title="Cоздать курс"
              class="ml-1 mt-1 cursor-pointer"
              @click.prevent.stop="createCourse()"
            >
              <PlusEmptyRoundedButton />
            </div>
          </div>

          <div class="relative text-gray-500 justify-center text-center text-sm border-t border-gray-400 -mb-1.5 mt-2.5">
            <div class="inline-block transform -translate-y-2.5 text-center bg-indigo-300 w-max px-1.5">
              Студенты
            </div>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Students' }"
              :exact="true"
            >
              <span @click="$emit('show-sidebar')">
                Студенты
              </span>
            </router-link>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Orders' }"
              :exact="true"
            >
              <span @click="$emit('show-sidebar')">
                Заказы
              </span>
            </router-link>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Feedback' }"
            >
              <span @click="$emit('show-sidebar')">
                Обратная связь
              </span>
            </router-link>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'SendEmail' }"
            >
              <span @click="$emit('show-sidebar')">
                Отправка писем
              </span>
            </router-link>
          </div>

          <div class="relative text-gray-500 justify-center text-center text-sm border-t border-gray-400 -mb-1.5 mt-2.5">
            <div class="inline-block transform -translate-y-2.5 text-center bg-indigo-300 w-max px-1.5">
              Сайт
            </div>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Headers' }"
            >
              <span @click="$emit('show-sidebar')">
                Хедеры
              </span>
            </router-link>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Categories' }"
            >
              <span @click="$emit('show-sidebar')">
                Категории
              </span>
            </router-link>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Timetable' }"
            >
              <span @click="$emit('show-sidebar')">
                Timetable
              </span>
            </router-link>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Stats' }"
            >
              <span @click="$emit('show-sidebar')">
                Статистика
              </span>
            </router-link>
          </div>

          <div class="relative text-gray-500 justify-center text-center text-sm border-t border-gray-400 -mb-1.5 mt-2.5">
            <div class="inline-block transform -translate-y-2.5 text-center bg-indigo-300 w-max px-1.5">
              Инструменты
            </div>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Links' }"
            >
              <span @click="$emit('show-sidebar')">
                Короткие ссылки
              </span>
            </router-link>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'CoursesTable' }"
            >
              <span @click="$emit('show-sidebar')">
                Таблица со всеми курсами
              </span>
            </router-link>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'PlacesToShare' }"
            >
              <span @click="$emit('show-sidebar')">
                Места размещения
              </span>
            </router-link>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Useful' }"
            >
              <span @click="$emit('show-sidebar')">
                Полезные ссылки
              </span>
            </router-link>
          </div>

          <div class="relative text-gray-500 justify-center text-center text-sm border-t border-gray-400 -mb-1.5 mt-2.5">
            <div class="inline-block transform -translate-y-2.5 text-center bg-indigo-300 w-max px-1.5">
              Настройки
            </div>
          </div>

          <div class="flex flex-row mb-1 text-sm">
            <router-link
              active-class="text-gray-500"
              :to="{ name: 'Settings' }"
            >
              <span @click="$emit('show-sidebar')">
                Настройки
              </span>
            </router-link>
          </div>

          <div class="relative text-gray-500 justify-center text-center text-sm border-t border-gray-400 -mb-1.5 mt-2.5">
            <div class="inline-block transform -translate-y-2.5 text-center bg-indigo-300 w-max px-1.5" />
          </div>

          <div class="flex flex-row mb-1">
            <a
              href=""
              @click.prevent="logout()"
            >
              Выйти
            </a>
          </div>
        </div>
      </div>
    </nav>

    <div
      v-if="show"
      class="z-20 fixed inset-0 bg-gray-700 opacity-50"
      @click.prevent="$emit('show-sidebar')"
    />
  </div>
</template>

<script>
import PlusEmptyRoundedButton from '@/components/app/UI/buttons/PlusEmptyRoundedButton'

export default {
  name: 'SidebarNav',
  components: {
    PlusEmptyRoundedButton
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    createCourse () {
      this.$router.push({ name: 'CourseEdit', params: { courseId: 'new' } }).catch(() => {})
    },
    createGroup () {
      this.$router.push({ name: 'GroupEdit', params: { id: 'new' } }).catch(() => {})
    },
    async logout () {
      this.$emit('close-menu')
      this.$router.push({ name: 'Login' }).catch(() => {})
    }
  }
}
</script>
