export default function currencyFilter (value, currency) {
  if (value === false) { return 'Не удалось посчитать' }

  let sum = 0

  if (value) {
    sum = +value / 100
  }

  if (!currency) { currency = 'RUB' }

  return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: currency }).format(sum)
}
